import { useState } from "react";
import AddAdmin from "./addAdmin";
import EditAdmin from "./editAdmin";
import UserProfile from "./userProfile";

const items = ["Add Admin", "Remove/ Edit Admin", "Edit My Profile"];


function AdminUser() {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      <div className="w-100  overflow-y-auto" style={{height:"78%"}}>
      <div  style={{width:"100%"}} className="align-items-center justify-content-center">
        <ul className="nav nav-tabs md-4">
          {items.map((item, index) => (
            <li
              className="nav-item p-0 m-0"
              key={index}
              onClick={() =>setActiveIndex(index)}
            >
              <a className={index === activeIndex ? "nav-link active text-success ps-1 pe-1" : "nav-link text-dark ps-1 pe-1"} style={{fontSize:"calc(8px + 0.7vw)"} }aria-current="page" href="#">
                {item}
              </a>
            </li>
          ))}  

        </ul>
        { activeIndex === 0 && <AddAdmin />}
        { activeIndex === 1 && <EditAdmin />}
        { activeIndex === 2 && <UserProfile />}
        </div>
      </div>
    </>
  );
}

export default AdminUser;
