import { useNavigate, useParams } from "react-router-dom";
import HeaderFooter from "./headerfooter";
import { useEffect, useState } from "react";
import { useGeneralInfoContext } from "../providers/genaralInfoProvider";
import Process from "./process";
import { useMessageBoxContext } from "../providers/messageBoxProvider";
import { useLoginContext } from "../providers/loginProvider";

function EmailVerification() {
  const { email } = useParams();
  const { generalInfo, setGeneralInfo } = useGeneralInfoContext();
  const { messageBox, setMessageBox } = useMessageBoxContext();
  const {user} = useLoginContext()

  useEffect( () => {
    setGeneralInfo({ ...generalInfo, isProcessing: true });
  },[])
   const [alert, setAlert] = useState({
    message: "Your email has been verified",
    color: "success",
    icon:"bi-check-lg",
    button:<button
                  className=" btn bg-warning text-dark"
                  onClick={() => navigate("/Login")}
                >
                  <i className="bi bi-arrow-left me-2"></i>Go To Login
                </button>
  });

  const handleResendMail = (e) => {
    setGeneralInfo({ ...generalInfo, isProcessing: false });
    fetch('/resendVerificationEmail',{
      method:"GET",
      headers: {
        "Content-Type": "application.json",
        "Authorization": "Bearer " + email,
      },
    })
    .then(res => res.json())
    .then(data => {
      setGeneralInfo({ ...generalInfo, isProcessing: false });
      if(data.error === 0){
        setAlert({
          ...alert,
          message: "Verification email has been sent, please check your email to verify",
    color: "success",
    icon:"bi-check-lg",
    button:<button
                  className=" btn bg-warning text-dark"
                  onClick={() => navigate("/Login")}
                >
                  <i className="bi bi-arrow-left me-2"></i>Go To Login
                </button>


        })
      } else {
        setAlert({
          ...alert,
          message: "Something when wrong",
    color: "danger",
    icon:"bi-x-lg",
    button:<button
                  className=" btn bg-warning text-dark"
                  onClick={() => navigate("/Login")}
                >
                  <i className="bi bi-arrow-left me-2"></i>Go To Login
                </button>


        })
      }
    })
  }

  useEffect(() => {
  
      fetch("/verifyEmail", {
        method: "GET",
        headers: {
          "Content-Type": "application.json",
          "Authorization": "Bearer " + email,
        },
      })
        .then((res) => res.status)
        .then((data) => {
          setGeneralInfo({ ...generalInfo, isProcessing: false });
          if (data === 200) {
            setAlert({
              ...alert,
              message: "Your email has been verified",
        color: "success",
        icon:"bi-check-lg",
        button:<button
                      className=" btn bg-warning text-dark m-auto"
                      onClick={() => navigate("/Login")}
                    >
                      <i className="bi bi-arrow-left me-2"></i>Go To Login
                    </button>
    
    
            })
          } else if (data === 401) {
          setAlert({
            ...alert,
            message: "Email Token has expired",
            color: "danger",
            icon:"bi-x-lg",
            button:<button
                    className=" btn bg-warning text-dark m-auto"
                    onClick={handleResendMail}
                  >
                    <i className="bi bi-arrow-left me-2"></i>Resend verification email
                  </button>
          })
          }
          setTimeout(() => {
            setMessageBox({
              ...messageBox,
              visible: false,
              message: "",
              color: "success",
            })
        },2000);
        });
     
  }, []);


  const navigate = useNavigate();

  return (
    <>
      <HeaderFooter>
        <div
          className="row d-flex  align-item-center justify-content-center pt-2"
          style={{ marginTop: "100px", marginBottom: "100px" }}
        >
          <div
            className={
              "alert  alert-info col-xs-10 col-sm-8 col-md-7 col-lg-6 d-flex flex-column align-item-center justify-content-center  border border-" +
              alert.color +
              " text-" +
              alert.color
            }
            role="alert"
          >
            {generalInfo.isProcessing ? <Process />
             : 
              <div>
                <h2 className="text-center mb-4">
                  <i className={"fs-1  border  p-2 ps-3 pe-3 rounded-circle " + alert.icon + " border-" + alert.color}></i>
                </h2>
                <p className="text-center">{alert.message}</p>
                <div className="d-flex align-item-center justify-content-center">
                {alert.button}
                </div>
              </div>
            }
          </div>
        </div>
      </HeaderFooter>
    </>
  );
}

export default EmailVerification;
