import { useForm, defaultValues, Controller } from "react-hook-form";
import { useLoginContext } from "../providers/loginProvider";
import { useRef, useState } from "react";
import { useMessageBoxContext } from "../providers/messageBoxProvider";
import { useGeneralInfoContext } from "../providers/genaralInfoProvider";
import Process from "./process";
import Select from "react-select";

function UserProfile() {
  const { user, setUser } = useLoginContext();
  const { messageBox, setMessageBox } = useMessageBoxContext();
  const { generalInfo, setGeneralInfo } = useGeneralInfoContext();
  const options = [
    { label: "Games", value: "Games" },
    { label: "Movies", value: "Movies" },
  ];

  const {
    register: passwordRegister,
    formState: { errors: passwordErrors },
    getValues: getPasswordValues,
    handleSubmit: handleChangePassword,
  } = useForm({ mode: "all" });
  const [alert, setAlert] = useState({
    visible: false,
    message: "",
    color: "text-danger",
  });

  const {
    control,
    register: editRegister,
    formState: { errors: editErrors },
    getValues: getEditValues,
    handleSubmit: handleEditProfile,
  } = useForm({ mode: "all", defaultValues: user });
  const upload = useRef(null);

  const [image, setImage] = useState("images/" + user.image);

  const handleBtnUploadClick = () => {
    upload.current.click();
  };

  const handleBtnChangePic = () => {
    const pic = "images/" + user.image;
    if (image !== pic) {
      const formData = new FormData();
      formData.append("file", upload.current.files[0]);
      formData.append("userid", user.userid);
      formData.append("name", user.othernames);
      setGeneralInfo({ ...generalInfo, isProcessing: true });
      fetch("/changeProfilePic", {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          setGeneralInfo({ ...generalInfo, isProcessing: false });
          if (data.error === 0) {
            setUser({ ...user, image: data.image });
            setMessageBox({
              ...messageBox,
              visible: true,
              color: "success",
              message: "Your profile pic was uploaded successfully",
            });
          } else {
            setMessageBox({
              ...messageBox,
              visible: true,
              color: "danger",
              message: "Something when wrong",
            });
          }
        });
    }
  };

  const onEditProfileSubmit = (formdata) => {
    formdata.preference = formdata.preference.map((p) => p.value);
    setGeneralInfo({ ...generalInfo, isProcessing: true });
    fetch("/updateUser", {
      method: "POST",
      body: JSON.stringify(formdata),
    })
      .then((res) => res.json())
      .then((data) => {
        setGeneralInfo({ ...generalInfo, isProcessing: false });
        if (data.error === 0) {
          setUser({
            ...user,
            username: data.user.surname,
            othernames: data.user.othernames,
            email: data.user.email,
            gender: data.user.gender,
            phone: data.user.phone,
            preference: data.user.preference.map((p) => ({
              value: p,
              label: p,
            })),
          });
          setAlert({
            ...alert,
            message: "Profile Update was successful",
            color: "text-success",
            visible: true,
          });
        } else {
          setAlert({
            ...alert,
            message: "something went wrong",
            color: "text-danger",
            visible: true,
          });
        }
        setTimeout(() => {
          setAlert({ ...alert, visible: false });
        }, 4000);
      });
  };

  const onChangePasswordSubmit = (data) => {
    setGeneralInfo({ ...generalInfo, isProcessing: false });
    fetch("/changePassword", {
      method: "POST",
      body: JSON.stringify({
        id: user.userid,
        oldpassword: data.oldpassword,
        newpassword: data.newpassword,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setGeneralInfo({ ...generalInfo, isProcessing: false });
        if (data.error === 0) {
          setMessageBox({
            ...messageBox,
            visible: true,
            color: "success",
            message: "Password was Changed successfully",
          });
        } else if (data.error === 1) {
          setMessageBox({
            ...messageBox,
            visible: true,
            color: "danger",
            message: "You entered wrong old password",
          });
        } else if (data.error === 2) {
          setMessageBox({
            ...messageBox,
            visible: true,
            color: "danger",
            message: "something when wrong",
          });
        }
      });
  };
  return (
    <>
      <div className="row  p-0 m-0 w-100  p-2">
        <div
          className="row m-0 p-0 d-flex align-items-center justify-content-center"
          style={{ alignContent: "flex-start" }}
        >
          <h4 className="text-center m-2">User Profile</h4>
          <div
            className="col-xs-12 col-sm-9 col-md-8 col-lg-4 bg-white shadow rounded"
            style={{ height: "550px" }}
          >
            <div className="mb-4">
              <div className="text-center">
                <img
                  src={image}
                  alt="avatar"
                  className="rounded-circle img-fluid"
                  style={{ width: "140px", height: "150px" }}
                />
                <h6 className="m-2">
                  {user.username + " , " + user.othernames}
                </h6>
                <p className="text-muted mb-2">{user.email}</p>
                {generalInfo.isProcessing ? (
                  <Process />
                ) : (
                  <div className="d-flex align-items-center justify-content-center">
                    <input
                      type="file"
                      name="upload"
                      ref={upload}
                      hidden
                      onChange={(e) => {
                        setImage(URL.createObjectURL(e.target.files[0]));
                      }}
                    />
                    <button
                      className="btn bg-dark btn-outline-light m-1 shadow"
                      onClick={handleBtnUploadClick}
                    >
                      <i className="fs-5 bi-arrow-up"></i>
                    </button>
                    <button
                      className="btn bg-danger  btn-outline-light m-1 shadow text-dark"
                      onClick={(e) => {
                        setImage("images/" + user.image);
                        upload.current.files = null;
                      }}
                    >
                      <i className="fs-5 bi-trash text-warning"></i>
                    </button>
                    <button
                      className="btn bg-warning  btn-outline-light m-1 shadow text-dark"
                      onClick={handleBtnChangePic}
                    >
                      Change Pic
                    </button>
                  </div>
                )}
                <h5 className="fs-5" style={{ color: "#ffa510" }}>
                  Change Password
                </h5>
                <div className="d-flex justify-content-center  m-0 p-0">
                  <form
                    className=" p-0 m-0  rounded  bg-light w-100"
                    onSubmit={handleChangePassword(onChangePasswordSubmit)}
                  >
                    <input type="text" hidden {...passwordRegister("userid")} />
                    <div className="col-md-12 m-1 ">
                      <input
                        type="password"
                        name="oldpassword"
                        placeholder="Enter Old Password"
                        className="form-control form-control-md"
                        {...passwordRegister("oldpassword", {
                          required: "Enter your old password",
                        })}
                      />
                      <p className="text-danger" style={{ fontSize: "13px" }}>
                        {passwordErrors.oldpassword?.message}
                      </p>
                    </div>

                    <div className="col-md-12 m-1">
                      <input
                        type="password"
                        name="newpassword"
                        placeholder="Enter New Password"
                        className="form-control form-control-md"
                        {...passwordRegister("newpassword", {
                          required: "Enter new password",
                          minLength: {
                            value: 8,
                            message: "password MUST be atleast 8 characters",
                          },
                          maxLength: {
                            value: 16,
                            message: "password should NOT excede 16 characters",
                          },
                          pattern: {
                            value:
                              /^(?=.*[0-9])(?=.*[!@#$%^&*.,])[a-zA-Z0-9!@#$%^&*.,]{8,16}$/,
                            message:
                              "password should contain atleast 1 uppercase, 1 lowercase, 1 number and 1 special character",
                          },
                        })}
                      />
                      <p className="text-danger" style={{ fontSize: "13px" }}>
                        {passwordErrors.newpassword?.message}
                      </p>
                    </div>

                    <div className="col-md-12 m-12">
                      <input
                        type="password"
                        name="confirmpassword"
                        placeholder=" Confirm Password"
                        className="form-control form-control-md"
                        {...passwordRegister("confirmpassword", {
                          required: "Confirm password",
                          validate: (match) => {
                            const password = getPasswordValues("newpassword");
                            return match === password || "Password DON'T match";
                          },
                        })}
                      />
                      <p className="text-danger" style={{ fontSize: "13px" }}>
                        {passwordErrors.confirmpassword?.message}
                      </p>
                    </div>

                    {generalInfo.isProcessing ? (
                      <Process />
                    ) : (
                      <div className="d-flex align-items-center justify-content-center ">
                        <button
                          type="submit"
                          className="btn bg-dark btn-outline-light m-1 shadow"
                        >
                          <i className="bi bi-save me-1"></i>Change Password
                        </button>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xs-12 col-sm-9 col-md-8 col-lg-7 bg-white shadow  m-2 rounded overflow-auto"
            style={{ height: "550px" }}
          >
            <form
              className="card-body p-1 pt-md-4 rounded  bg-light"
              onSubmit={handleEditProfile(onEditProfileSubmit)}
            >
              <h5
                className="mb-md-4 mt-0 text-center"
                style={{ borderRadius: "5px", color: "#ffa510" }}
              >
                Edit User Profile
              </h5>
              {alert.visible && (
                <div className="alert  alert-info  fade show" role="alert">
                  <button
                    type="button"
                    className="btn text-danger  float-end"
                    style={{ position: "relative", top: "-10px" }}
                    onClick={(e) => {
                      setAlert({ ...alert, visible: false });
                    }}
                  >
                    X
                  </button>
                  <span className={alert.color}>{alert.message}</span>
                </div>
              )}

              <div className="row">
                <div className="col-md-6 mb-2">
                  <div className="form-outline">
                    <label className="form-label text-dark" htmlFor="surname">
                      SurName
                    </label>
                    <input
                      type="text"
                      name="surname"
                      className="form-control form-control-md"
                      {...editRegister("username", {
                        required: "Surname is required",
                        minLength: {
                          value: 3,
                          message:
                            "Name can NOT be less  than three characters",
                        },
                        maxLength: {
                          value: 15,
                          message: "Name can NOT excede 15 characters",
                        },
                        pattern: {
                          value: /[a-zA-Z]+/,
                          message: "Name can only be letter",
                        },
                      })}
                    />
                    <p className="text-danger" style={{ fontSize: "13px" }}>
                      {editErrors.surname?.message}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 mb-2">
                  <div className="form-outline">
                    <label
                      className="form-label text-dark"
                      htmlFor="othersNames"
                    >
                      Other Names
                    </label>
                    <input
                      type="text"
                      name="otherNames"
                      className="form-control form-control-md"
                      {...editRegister("othernames", {
                        required: "Name is required",
                        minLength: {
                          value: 3,
                          message:
                            "Name can NOT be less  than three characters",
                        },
                        maxLength: {
                          value: 15,
                          message: "Name can NOT excede 15 characters",
                        },
                        pattern: {
                          value: /[a-zA-Z]+/,
                          message: "Name can only be letter",
                        },
                      })}
                    />
                    <p className="text-danger" style={{ fontSize: "13px" }}>
                      {editErrors.othernames?.message}
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-2 d-flex align-items-center">
                  <div className="form-outline datepicker w-100">
                    <label htmlFor="address" className="form-label text-dark">
                      Preference
                    </label>
                    <Controller
                      control={control}
                      name="preference"
                      rules={{ required: "preference" }}
                      render={({
                        field: { value, onChange, name, ref, ...field },
                      }) => {
                        return (
                          <Select
                            name={name}
                            ref={ref}
                            isMulti
                            placeholder="Select preference"
                            className="form-control form-control-md p-0 "
                            options={options}
                            value={value}
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            onChange={(option) => {
                              onChange(option);
                            }}
                            {...field}
                          />
                        );
                      }}
                    />
                    <p className="text-danger" style={{ fontSize: "13px" }}>
                      {editErrors.preference?.message}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <h6 className="mb-2 pb-1  text-dark">Gender: </h6>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      id="femaleGender"
                      value="Female"
                      {...editRegister("gender", {
                        required: "Gender is required",
                      })}
                    />
                    <label
                      className="form-check-label text-dark"
                      htmlFor="femaleGender"
                    >
                      Female
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      id="maleGender"
                      value="Male"
                      {...editRegister("gender", {
                        required: "Gender is required",
                      })}
                    />
                    <label
                      className="form-check-label text-dark"
                      htmlFor="maleGender"
                    >
                      Male
                    </label>
                  </div>
                  <p className="text-danger" style={{ fontSize: "13px" }}>
                    {editErrors.gender?.message}
                  </p>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-6 mb-2 pb-2">
                  <div className="form-outline">
                    <label className="form-label text-dark" htmlFor="email">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="form-control form-control-md"
                      {...editRegister("email", {
                        required: "Email is required",
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "invalid email address ",
                        },
                      })}
                    />
                    <p className="text-danger" style={{ fontSize: "13px" }}>
                      {editErrors.email?.message}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 mb-4 pb-2">
                  <div className="form-outline">
                    <label className="form-label text-dark" htmlFor="phone">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      name="phone"
                      className="form-control form-control-md"
                      {...editRegister("phone", {
                        required: "Phone number id required",
                        minLength: {
                          value: 10,
                          message: "Phone number should be 10 characters",
                        },
                        maxLength: {
                          value: 10,
                          message: "Phone number should be 10 characters",
                        },
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Phone number can only numbers",
                        },
                      })}
                    />
                    <p className="text-danger" style={{ fontSize: "13px" }}>
                      {editErrors.phone?.message}
                    </p>
                  </div>
                </div>
              </div>

              {generalInfo.isProcessing ? (
                <Process />
              ) : (
                <div className="d-flex align-items-center justify-content-center">
                  <button
                    type="submit"
                    className="btn bg-dark btn-outline-light m-1 mt-4 shadow"
                  >
                    Update User Profile
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserProfile;
