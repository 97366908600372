
function Processing() {

 
  return (
    <>
      <section className="section-products d-flex align-items-center justify-content-center bg-transparent shadow m-0 p-0" style={{zIndex:"9"}}>
       
            <i className="spinner-grow text-danger bg-transparent"></i>
        
      </section>
    </>
  );
}
 export default Processing;