import { useEffect, useState } from "react";
import DataTable from "react-data-table-component"
import { useCardDetailContext } from "../providers/cardDetailProvider";
import { useMessageBoxContext } from "../providers/messageBoxProvider";
import { useProductsContext } from "../providers/productsProvider";
import AdminProductCard from "./AdminProductCard";
import { useLoginContext } from "../providers/loginProvider";
import { useGeneralInfoContext } from "../providers/genaralInfoProvider";
import { useOrderContext } from "../providers/orderProvider";
import OrderCard from "./orderCard";




function AdminOrder(){

  const {messageBox, setMessageBox} = useMessageBoxContext()
  const { setProducts} = useProductsContext()
  const {order,setOrder,orderItems,setOrderItems} = useOrderContext()
  const {generalInfo,setGeneralInfo} = useGeneralInfoContext()

  const handleDelete = (row) =>{
    setMessageBox({...messageBox, visible:true, deleteIndex: row.id,deleteItem:"order",isDelete:true ,message:"Are you sure you want to delete order with id " + row.id + " from the database ?"})
  }

  const handleViewOrder = (row) => {
    setGeneralInfo({...generalInfo, isProccessing:true})
    fetch("/getOrderItems", {
      method: "POST",
      body:JSON.stringify({id:row.id,user:"admin"})
    })
      .then((res) => res.json())
      .then((data) => {
        setGeneralInfo({...generalInfo, isProccessing:false})
       if(data.error===0){
        setProducts(data.products)
        setOrder({...order,visible:true,detail:row})
       }else{
        setMessageBox({...messageBox, visible:true ,message:"something went wrong",color:"danger"})
       }

       setTimeout(() => {
        setMessageBox({...messageBox, visible:false,message:"",isDelete:false,color:"success",deleteItem:""})
       },3000)
      });
    }
  

 
  
  const columns =  [
    {
      name:"Action",
      button:true,
      cell: (row) =>(
        <>
        <button className="btn btn-dark me-1 fs-5 bi-display "  onClick={() => handleViewOrder(row)}></button>
        <button className="btn btn-danger me-1 fs-5 bi-trash" onClick={() => handleDelete(row)}></button>
        </>
      )
  
    },
    {
      name: "Order ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Order By",
      selector : row => row.othernames,
    },
    {
      name: "Sub Total",
      selector: (row) => row.subtotal,
      sortable:true
    },
   
    {
      name : "Tax",
      selector : row => row.tax,
        sortable :true,
    },
    {
      name: "Total",
      selector : row => row.subtotal + row.tax,
        sortable: true,
      },
    {
      name : "Order Date",
      selector : row => row.orderdate,
        sortable :true,
    },
      {
      name : "Status",
      cell: (row) =>(
        <>
        {row.paid === 1 ? <div className="text-success">Paid</div> :  <div className="text-danger"> Not Paid</div>}
        </>
      )
    },
     
       
  ]

    useEffect(() => {
        fetch("/getOrders", {
          method: "POST",
          body:JSON.stringify({user:"admin",status:1})
        })
          .then((res) => res.json())
          .then((data) => {
            setOrderItems(data)
          });
      }, [generalInfo.refreshOrder]);

    return (
        <>
        <div className="h-auto w-100 m-0 p-0  overflow-auto">
       
        <div className="w-100">
                <DataTable
                title="Orders"
                columns={columns}
                data={orderItems}
                dense
                pagination
                striped
                theme="solarized"
                />
                
            </div>
            </div>
        </>
    )
}

export default AdminOrder;