import { useEffect, useState } from "react";
import { useGeneralInfoContext } from "../providers/genaralInfoProvider";
import HeaderFooter from "./headerfooter";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Process from "./process";

function ResetPassword() {
  const { generalInfo, setGeneralInfo } = useGeneralInfoContext();
  const [valid, setValid] = useState(false);
  const [userid, setUserid] = useState(-1);
  const params = useParams();
  const [isToken,setIsToken]  = useState(false)
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({ mode: "all" });
  const [alert, setAlert] = useState({
    visible: false,
    message: "",
    color: "danger",
  });

  
  useEffect(() => {
    setGeneralInfo({ ...generalInfo, isHomePage: false });
    fetch("/verifyToken", {
      method: "GET",
      headers: {
        "Content-Type": "application.json",
        "Authorization": "Bearer " + params.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setIsToken(true)
        if (data.error == 0) {
          setValid(true);
          setUserid(data.userid)
        } else {
          setValid(false);
        }
      });
  }, []);

  const onSubmit = (data) => {
    setGeneralInfo({...generalInfo, isProcessing:true})
    fetch("/resetPassword", {
        method: "POST",
        body: JSON.stringify({userid:userid, password : data.newpassword})
      })   
        .then((res) => res.json())  
        .then((data) => {
            setGeneralInfo({...generalInfo, isProcessing:false})
          if(data.error === 0){
            setAlert({
                ...alert,
                visible: true,
                message: "Password was Changed successfully",
                color:"success"
              });
              setTimeout(()=>{
                navigate("/Login")
              },2000)
          }else  if(data.error === 1){
            setAlert({
                ...alert,
                visible: true,
                message: "You entered wrong old password",
                color:"danger"
              });
          }else  if(data.error === 2){
            setAlert({
                ...alert,
                visible: true,
                message: "something when wrong",
                color:"danger"
              });
          }
          setTimeout(() => {
            setAlert({
              ...alert,
              visible: false,
          })
           }, 2000);
        })
}  

  return (
    <>
      <HeaderFooter>
        { 
        
        !isToken ?

        <Process/>

        :
        valid ?
          <div className="login col-xs-10 m-0 col-sm-7 col-md-6 col-lg-5 col-xl-4 mb-3 bg-dark">
          <div className="card-body">
            <div className="pt-4 pb-2">
              <h5 className="card-title text-white text-center pb-4 fs-4">
                Reset Your Password
              </h5>
            </div>

            {alert.visible && (
              <div className={"alert  alert-info  fade show border border-" + alert.color + " text-" + alert.color} role="alert">
                <button
                  type="button"
                  className="btn text-danger  float-end"
                  style={{ position: "relative", top: "-10px" }}
                  onClick={(e) => {
                    setAlert({ ...alert, visible: false });
                  }}
                >
                  X
                </button>
                <span className={alert.color}>{alert.message}</span>
              </div>
            )}

            <form className="row m-0 p-0 needs-validation w-100" autocomplete="off" onSubmit={handleSubmit(onSubmit)}>
              <div className="row mb-3 m-0">
              
                <div className="col-md-12 mb-2 pb-2">
                    <label
                      className="form-label text-white"
                      htmlFor="newpassword"
                    >
                      Enter New Password
                    </label>
                    <input
                      type="password"
                      name="newpassword"
                      className="form-control form-control-md"
                      {...register("newpassword", {
                        required: "Enter new password",
                        minLength: {
                            value: 8,
                            message: "password MUST be atleast 8 characters",
                          },
                          maxLength: {
                            value: 16,
                            message: "password should NOT excede 16 characters",
                          },
                          pattern: {
                            value:
                              /^(?=.*[0-9])(?=.*[!@#$%^&*.,])[a-zA-Z0-9!@#$%^&*.,]{8,16}$/,
                            message:
                              "password should contain atleast 1 uppercase, 1 lowercase, 1 number and 1 special character",
                          },
                       
                      })}
                    />
                    <p className="text-danger" style={{ fontSize: "13px" }}>
                      {errors.newpassword?.message}
                    </p>
                </div>

                <div className="col-md-12 mb-2 pb-2">
                    <label
                      className="form-label text-white"
                      htmlFor="confirmpassword"
                    >
                    Confirm Password
                    </label>
                    <input
                      type="password"
                      name="confirmpassword"
                      className="form-control form-control-md"
                      {...register("confirmpassword", {
                        required: "Confirm password",
                        validate: (match) => {
                            const password = getValues("newpassword");
                            return match === password || "Password DON'T match";
                          },
                      })}
                    />
                    <p className="text-danger" style={{ fontSize: "13px" }}>
                      {errors.confirmpassword?.message}
                    </p>
                </div>
                <div className="col-12">
                { generalInfo.isProcessing ?
                <Process/>
                :
                  <button
                  className="btn btn-warning text-dark w-100"
                  type="submit"
                >
                  Reset Password
                </button>}
              </div>
               
              </div>

            </form>
          </div>
        </div>
        : <div className=" align-items-center justify-content-center">
          <h4 className="text-center m-2">TOKEN ERROR</h4>
          <h5 className="text-danger text-center m-2">Your Token is Invalid or has expires</h5>
        </div>
        }
      </HeaderFooter>
    </>
  );
}

export default ResetPassword;
