import { useState,useContext,createContext} from "react";


const CardDetailContext = createContext();
function CardDetailProvider({children}) {

    const [cardDetail, setCardDetail] = useState({
        visible:false,
        id:-1,
        name:"",
        days:1,
        price:0,
        date:"",
        image:"",
        description:"",
        category:"",
        subCategory:"",
        quantity:"",
        productType:"",
        returndate:new Date(),
        pickupdate:new Date()
    })


    return (
           <CardDetailContext.Provider value={{ cardDetail,setCardDetail }}>
                {children}
            </CardDetailContext.Provider>
    )
}

export const useCardDetailContext = () => useContext(CardDetailContext);
export default CardDetailProvider;