import { createContext, useContext, useState } from "react";

const MessageBoxContext = createContext();

function MessageBoxProvider({children}) {

    const [messageBox, setMessageBox] = useState({
        visible:false,
        deleteIndex: -1,
        isDelete:false,
        deleteItem:"",
        message:"",
        status:false,
        color:"success"
    })


    return (
           <MessageBoxContext.Provider value={{ messageBox,setMessageBox }}>
                {children}
            </MessageBoxContext.Provider>
    )
}

export const useMessageBoxContext = () => useContext(MessageBoxContext);
export default MessageBoxProvider;