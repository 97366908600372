import { useState } from "react";
import { useCategoryContext } from "../providers/categoryProvider";
import { useGeneralInfoContext } from "../providers/genaralInfoProvider";
import { useProductsContext } from "../providers/productsProvider";

function Sidenav({ onSideMenuClick,onSidenavToggler, selectedindex }) {

  const { products, setProducts } = useProductsContext();
  const { categories } = useCategoryContext();
  const {generalInfo, setGeneralInfo} = useGeneralInfoContext()
  const [style, setStyle] = useState("side");

  const handleSidenavToggler = (e) => {
    if(style === "side"){
      setStyle("sidetoggle")
    }else{
      setStyle("side")
    }
  }

  return (
    <>
      <aside className={style} style={{backgroundColor:"rgba(16,23,19,0.9)"}} >
        <h5 className="text-white align-center">Category</h5>
        <hr className="text-white" />
        {generalInfo.page !== "Loyalty Points" && 
        <button onClick={handleSidenavToggler} style={{position:"absolute",left:"200px",top:"50px",backgroundColor:"rgb(16,23,19)"}} className="p-1 ps-2 pe-2 ms-1 d-sm-none d-md-none d-lg-none d-xl-none d-xxl-none"><i className={style === "side" ? "bi bi-menu-button-wide" : "bi bi-x-lg"}></i></button>
  }
        <ul className="nav nav-pills flex-column sidenav m-0 p-0" id="sidenav">
          {categories.map((item, index) => (
            <li
              className="nav-item"
              key={index}
              onClick={() => onSideMenuClick(item, index)}
            >
              <a
                className={
                  selectedindex === index
                    ? "nav-link ms-2 me-2 bg-warning text-dark"
                    : "nav-link text-white ms-0 me-0"
                }
                href="#"
                id={item}
              >
                {item}
              </a>
            </li>
          ))}
        </ul>
      </aside>
    </>
  );
}

export default Sidenav;
