import DropDown from "./dropdown";
import Card from "./card";
import { useProductsContext } from "../providers/productsProvider";
import { useGeneralInfoContext } from "../providers/genaralInfoProvider";
import CardPlaceholder from "./cardPlaceholder";

const movietype = ["Movies", "TV Shows"]
const sortlist = ["Name", "Latest"]
const placeHolder = [1,1,1,1,1,1]


function Content({isMovie,onChange,category}) {

  const {filterProducts } = useProductsContext()
  const {generalInfo} = useGeneralInfoContext()

  return (
    <>
     <div className="row ">
        <div className="m-0 p-3 d-flex align-items-center justify-content-left bg-dark" style={{ height: "45px",zIndex:"0.9"}}>
         {isMovie && <DropDown items={movietype} label={category === "" ? "Movies" : category} onChange={onChange} />}
          <DropDown items={sortlist} label="Sort By:" />
        </div>
        <div className="row m-0 p-0 align-items-center justify-content-center h-100">
          <h5 className="text-center m-2">{generalInfo.contentHeading}</h5>
          
          {filterProducts.length === 0 ? 
           placeHolder.map(() => (
            <CardPlaceholder />
          ))          
          :
          filterProducts.map((product) => (
            <Card product={product} />
          ))
          }

         
        </div>  
      </div>
    </>
  );
}

export default Content;
