import HeaderFooter from "./headerfooter";
import {useEffect, useState} from "react";
import {useGeneralInfoContext} from "../providers/genaralInfoProvider";
import Card from "./card";
import Process from "./process";
import CardPlaceholder from "./cardPlaceholder";



function Games(props) {
    const {generalInfo, setGeneralInfo} = useGeneralInfoContext();
    const [games, setGames] = useState([]);
    const placeHolder = [1,1,1,1,1,1]

    useEffect(() => {
        setGeneralInfo({...generalInfo, isHomePage: true});
        fetch("/getGames", {
            method: "POST",
            body: JSON.stringify({limit:10,offset:0}),
        })
            .then((res) => res.json())
            .then((data) => {
                setGeneralInfo({ ...generalInfo, isProcessing: false });
                setGames(data.products);
            });
    }, []);

  
    return (
        <>
                 <div className="w-100 bg-dark">
                <div className="bg-dark homecontent  p-1 overflow-scroll" style={{height: "50vw"}}>
                    <h3 className="w-100 text-center " style={{textShadow:"2px 2px  #000000"}} >Latest Video Games</h3>
                    <div className="row d-flex align-item-center justify-content-center">
                    {generalInfo.isProcessing && 
                     placeHolder.map(() => (
                        <CardPlaceholder />
                      ))
                    }
                    {games.map((game,index) => (
                            <Card product={game} />
                        ))}
                    </div>
                    </div>
                </div>
        </>
    );
}

export default Games;
