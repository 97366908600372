import { useState,useContext,createContext} from "react";

const LoginContext = createContext();
function LoginProvider({children}) {
    const [user, setUser] = useState(null
    //    {
    //     isLoggedIn: 0,
    //     isAdmin: false,
    //     userid:-1,
    //     username:"",
    //     othernames:"",
    //     image:"",
    //     email:"",
    //     gender:"",
    //     phone:"",
    //     preference:[],
    //     points:0,
    //     redeem:0,    
    // }
    );
 
    return (
           <LoginContext.Provider value={{ user,setUser }}>
                {children}
            </LoginContext.Provider>
    )
}

export const useLoginContext = () => useContext(LoginContext);
export default LoginProvider;