import { useEffect, useRef, useState } from "react";
import { Controller, useForm, useController } from "react-hook-form";
import logo from "../assets/product.jpg";
import { useCardDetailContext } from "../providers/cardDetailProvider";
import { useMessageBoxContext } from "../providers/messageBoxProvider";
import MessageBox from "./messagebox";
import { useGeneralInfoContext } from "../providers/genaralInfoProvider";
import { useCartContext } from "../providers/cartProvider";
import Process from "./process";

function AdminProductCard({ type }) {
  const { cardDetail, setCardDetail } = useCardDetailContext();
  const upload = useRef(null);
  const [productType, setProductType] = useState("");
  const { messageBox, setMessageBox } = useMessageBoxContext();
  const { generalInfo, setGeneralInfo } = useGeneralInfoContext();
  const { Cart, setCart } = useCartContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    reset,
  } = useForm({ mode: "all" });
  const { field, rules } = useController({ name: "productType", control });

  let date = new Date();
  useEffect(() => {
    let date = new Date()
      .toLocaleDateString("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .split("/")
      .reverse()
      .join("-");
    setValue("date", date);
    if (type === "formUpdate") {
      setImage("images/" + cardDetail.image);
      const d = new Date(cardDetail.date);
      let date = d
        .toLocaleDateString("en-GB", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .split("/")
        .reverse()
        .join("-");
      setValue("name", cardDetail.name);
      setValue("productType", cardDetail.productType);
      setValue("price", cardDetail.price);
      setValue("date", date);
      setProductType(cardDetail.productType);
      setValue("subCategory", cardDetail.subCategory);
      setValue("description", cardDetail.description);
      setValue("category", cardDetail.category);
      setValue("quantity", cardDetail.quantity);
      setValue("id", cardDetail.id);
    }
  }, []);

  const moviesCategory = ["Movies", "TV Show"];
  const subCategory = [
    "Adventure",
    "Action",
    "romantic",
    "Sci-Fiction",
    "Horror",
  ];
  const gamesCategory = ["Action", "Racing", "Football"];
  let img = "images/" + cardDetail.image;

  const [image, setImage] = useState("images/product.jpg");

  const handleBtnUploadClick = () => {
    upload.current.click();
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("price", data.price);
    formData.append("category", data.category);
    formData.append("date", data.date);
    formData.append("description", data.description);
    formData.append("quantity", data.quantity);
    setGeneralInfo({ ...generalInfo, isProcessing: true });
    if (type === "formAdd") {
      formData.append("file", data.image);
      fetch("/addProduct", {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((result) => {
          setGeneralInfo({ ...generalInfo, isProcessing: false });
          if (result.error === 0) {
            setMessageBox({
              ...messageBox,
              visible: true,
              message: "New product was added successfully",
            });
          } else if (result.error === 1) {
            setMessageBox({
              ...messageBox,
              visible: true,
              message:
                "Product '" + result.name + "' already exist in the database",
            });
          } else if (result.error === 2) {
            setMessageBox({
              ...messageBox,
              visible: true,
              message: "something when wrong",
            });
          }
          setCardDetail({ ...cardDetail, visible: false });
          setTimeout(() => {
            reset();
            setMessageBox({
              ...messageBox,
              deleteItem: -1,
              visible: false,
              message: "",
            });
          }, 3000);
        });
    } else if (type === "formUpdate") {
      let id = Number.parseInt(data.id);
      let isImageSet = false;
      formData.append("id", id);
      if (image !== img && image !== "images/product.jpg") {
        formData.append("file", data.image);
        isImageSet = true;
      }
      formData.append("isImageSet", isImageSet);
      setGeneralInfo({ ...generalInfo,isProcessing:true})
      fetch("/updateProduct", {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((result) => {
          setGeneralInfo({ ...generalInfo, isProcessing: false });
          if (result.error === 0) {
            setMessageBox({
              ...messageBox,
              visible: true,
              message: "Product was updated successfully",
            });
          } else if (result.error === 1) {
            setMessageBox({
              ...messageBox,
              visible: true,
              message: "something when wrong" + result.message,
            });
          }
          setCardDetail({ ...cardDetail, visible: false });
          setTimeout(() => {
            reset();
            setMessageBox({ ...messageBox, visible: false, message: "" });
          }, 3000);
        });
    }
    setGeneralInfo({
      ...generalInfo,
      refresh: Math.floor(Math.random() * 1000),
    });
  };

  return (
    <>
      <section
        className="section-products d-flex align-items-center fixed-top justify-content-center shadow  m-0 p-0"
        style={{ zIndex: "7",backgroundColor:"rgba(16,23,19,0.7)" }}
      >
        <div className="row bg-white rounded shadow product-card p-0 m-0 overflow-scroll ">
          <div className="  d-flex justify-content-end">
            <button
              type="button"
              className="btn-close text-danger"
              aria-label="Close"
              onClick={() => {
                reset();
                setCardDetail({ ...cardDetail, visible: false });
              }}
            ></button>
          </div>
          <h3 className="text-center">
            {type !== "formUpdate" ? "Add Product " : "Update Product"}
          </h3>
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-4 h-auto">
            <img
              src={image}
              alt="product"
              className="product-img shadow md-2"
            />

            <p className="text-danger" style={{ fontSize: "13px" }}>
              {errors.image?.message}
            </p>
            <button
              className="btn bg-dark btn-outline-light m-1 shadow"
              onClick={handleBtnUploadClick}
            >
              <i className="fs-5 bi-arrow-up"></i>
            </button>
            <button
              className="btn bg-danger  btn-outline-light m-1 shadow text-dark"
              onClick={(e) => {
                setImage("images/product.jpg");
                upload.current.files = null;
              }}
            >
              <i className="fs-5 bi-trash"></i>
            </button>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
            <form
              className="card-body p-4 p-md-2 rounded  bg-light"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="row">
                <div className="col-md-6 mb-2">
                  <Controller
                    control={control}
                    name="image"
                    rules={
                      type !== "formUpdate" && {
                        required: "Upload product image",
                      }
                    }
                    render={({
                      field: { value, onChange, name, ...field },
                    }) => {
                      return (
                        <input
                          {...field}
                          value={value?.fileName}
                          onChange={(event) => {
                            onChange(event.currentTarget.files[0]);
                            setImage(
                              URL.createObjectURL(event.target.files[0])
                            );
                          }}
                          type="file"
                          name="image"
                          ref={upload}
                          hidden
                        />
                      );
                    }}
                  />

                  <input type="text" {...register("id", {})} hidden />
                  <div className="">
                    <label className="form-label text-dark" htmlFor="name">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-md"
                      {...register("name", {
                        required: "Enter product name",
                        minLength: {
                          value: 3,
                          message:
                            "Name can NOT be less  than three characters",
                        },
                        maxLength: {
                          value: 50,
                          message: "Name can NOT excede 50 characters",
                        },
                        pattern: {
                          value: /[a-zA-Z]+/,
                          message: "Name can only be letter",
                        },
                      })}
                    />
                    <p className="text-danger" style={{ fontSize: "13px" }}>
                      {errors.name?.message}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 mb-2 pb-0">
                  <div className="">
                    <label className="form-label text-dark" htmlFor="price">
                      Price
                    </label>
                    <input
                      type="text"
                      name="price"
                      className="form-control form-control-md"
                      {...register("price", {
                        required: "price is required",
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Price can only be numbers",
                        },
                      })}
                    />
                    <p className="text-danger" style={{ fontSize: "13px" }}>
                      {errors.price?.message}
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
              <div className="col-md-6 mb-2 pb-0">
                  <div className="">
                    <label className="form-label text-dark" htmlFor="category">
                      Category
                    </label>
                    <Controller
                      control={control}
                      name="category"
                      render={({ field: { name, onChange, value } }) => (
                        <select
                          className="form-select"
                          {...register("category", {
                            required: "Select category",
                          })}
                          value={value}
                          onChange={(e) => {
                            onChange((value = e.target.value));
                          }}
                        >
                          <option value="" selected>
                            select category
                          </option>

                          {gamesCategory.map((item, index) => (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    <p className="text-danger" style={{ fontSize: "13px" }}>
                      {errors.category?.message}
                    </p>
                  </div>
                </div>

                <div className="col-md-6 mb-2 pb-0">
                  <div className="form-outline">
                    <label className="form-label text-dark" htmlFor="date">
                      Release Date
                    </label>

                    <input
                      type="date"
                      max={date
                        .toLocaleDateString("en-GB", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        })
                        .split("/")
                        .reverse()
                        .join("-")}
                      className="form-control form-control-md"
                      {...register("date", {
                        required: "Select release date",
                      })}
                    />

                    <p className="text-danger" style={{ fontSize: "13px" }}>
                      {errors.date?.message}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mb-2 pb-0">
                  <div className="">
                    <label className="form-label text-dark" htmlFor="price">
                      Product Quantity
                    </label>
                    <input
                      type="text"
                      name="quantity"
                      className="form-control form-control-md"
                      {...register("quantity", {
                        required: "quantity is required",
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "quantity can only be numbers",
                        },
                      })}
                    />
                    <p className="text-danger" style={{ fontSize: "13px" }}>
                      {errors.quantity?.message}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mb-2 pb-0">
                  <div className="">
                    <label
                      className="form-label text-dark"
                      htmlFor="description"
                    >
                      Description
                    </label>
                    <textarea
                      type="text"
                      name="description"
                      className="form-control form-control-md"
                      {...register("description", {
                        required: "description is required",
                        minLength: {
                          value: 20,
                          message:
                            "description can NOT be less  than 20 characters",
                        },
                        maxLength: {
                          value: 200,
                          message: "description can NOT excede 200 characters",
                        },
                      })}
                    ></textarea>
                    <p className="text-danger" style={{ fontSize: "13px" }}>
                      {errors.description?.message}
                    </p>
                  </div>
                </div>
              </div>
            
              <div className= "d-flex align-items-center justify-content-center ">
              
                {  generalInfo.isProcessing ? <Process/>
                :
                 <>
                  <button
                    type="submit"
                    className={type === "formAdd" ? "btn bg-dark btn-outline-light m-1 shadow" : "d-none"}
                  >
                    <i className="bi bi-save me-1"></i>Save
                  </button>
               
               
                  <button className={type === "formUpdate" ? "btn bg-warning  btn-outline-light m-1 shadow text-dark" : "d-none"}>
                    <i className="bi bi-arrow-up me-1"></i>Update
                  </button>
                  </>
                 }
              </div>
             
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default AdminProductCard;
