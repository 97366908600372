import "./card.css";

function CardPlaceholder() {

  return (
    <>
      <div
        className="p-2 m-1 bg-white mt-1 shadow product"
      >
        < div className=" mb-2" style={{ height: "70%"}}>
        <img
          src="..."
          className="card-img-top p-1 align-center placeholder-glow border rounded "
          alt=""
          style={{ height: "100%", width:"100%",backgroundColor:"#D6D6D6" }}
        />
        </div>
        
        <div className="">
          <h6 className="p-0 m-0 text-dark p-0 placeholder-glow"><span className="placeholder col-12 p-3"></span></h6>
          <p className="p-0 m-0  placeholder-glow"  style={{color:"#ff9500"}}><span className="placeholder col-10"></span><span class="placeholder col-8"></span></p>
        </div>
      </div>
    </>
  );
}

export default CardPlaceholder;
