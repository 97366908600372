import gamepad from "../assets/gamepad.jpg";
import HeaderFooter from "./headerfooter";
import {useGeneralInfoContext} from "../providers/genaralInfoProvider";
import Movies from "./movies";
import Games from "./games";
import { useEffect } from "react";


class TypeWriter {
    constructor(txtElement, words, wait = 3000) {
        this.txtElement = txtElement;
        this.words = words;
        this.txt = '';
        this.wordIndex = 0;
        this.wait = parseInt(wait, 10);
        this.type();
        this.isDeleting = false;
    }

    type() {
        // Current index of word
        const current = this.wordIndex % this.words.length;
        // Get full text of current word
        const fullTxt = this.words[current];

        // Check if deleting
        if(this.isDeleting) {
            // Remove char
            this.txt = fullTxt.substring(0, this.txt.length - 1);
        } else {
            // Add char
            this.txt = fullTxt.substring(0, this.txt.length + 1);
        }

        // Insert txt into element
        this.txtElement.innerHTML = `<span class="txt"> ${this.txt}</span>`;

        // Initial Type Speed
        let typeSpeed = 300;

        if(this.isDeleting) {
            typeSpeed /= 2;
        }

        // If word is complete
        if(!this.isDeleting && this.txt === fullTxt) {
            // Make pause at end
            typeSpeed = this.wait;
            // Set delete to true
            this.isDeleting = true;
        } else if(this.isDeleting && this.txt === '') {
            this.isDeleting = false;
            // Move to next word
            this.wordIndex++;
            // Pause before start typing
            typeSpeed = 500;
        }

        setTimeout(() => this.type(), typeSpeed);
    }
}

function Home(props) {

    const {generalInfo, setGeneralInfo} = useGeneralInfoContext()
    
    const words = [" Playstation Gaming.", " Movies & TV Shows.", " PC & Laptop Games.", " Video Games For Rent."];

    const loadTypewriter = () => {
        const txtElement = document.querySelector('.txt-type');
        const wait = Number.parseInt(txtElement.getAttribute('data-wait'));
        // Init TypeWriter
        new TypeWriter(txtElement, words, wait);
    };



    return (
        <>
            <HeaderFooter>
                { generalInfo.homeSelectedIndex === 0 && <div className="w-100 bg-dark" onLoad={loadTypewriter}>
                    {/*Carousels*/}
                    <div className="bg-dark showcase p-4" style={{height: "45vw"}}>
                        <h1 style={{fontSize:"calc(30px + 2vw)"}}>Enjoy Exclusive
                            <span className="txt-type" data-wait="5000" data-words={{words}}></span>
                        </h1>
                        <h3>Where imagination comes to life.</h3>
                    </div>

                    {/*About us*/}
                    <div className="position-relative bg-dark p-1 m-0 pb-5 row ">
                        <div className="w-7  col-md-5">
                            <img src={gamepad} alt="Game pad." className="h-100 w-100"/>
                        </div>
                        <div className="w-10 ps-4 text-light col-md-7" style={{fontSize:"calc(11px + 0.8vw)"}}>
                            <h2 className="h-2 m-2 text-center ">About us</h2>
                            <p>
                                Welcome to Quadro Gamers, your one-stop shop for all your entertainment needs.
                                We have a wide selection of movies and video games, from the latest blockbusters to cult classics.
                                We also offer a variety of gaming accessories, so you can get the most out of your gaming experience.

                            </p>
                            <p>
                                Our mission is to provide our customers with the best possible entertainment experience. We are committed
                                to providing you with a wide selection of movies and video games, at competitive prices. We also offer
                                a friendly and knowledgeable staff who are always happy to help you find the perfect movie or game for you.
                            </p>
                            <p>

                                Whether you're a casual gamer or a hardcore enthusiast, we have something for everyone. So come on in
                                and let us help you find your next great entertainment experience. We offer the following services:
                            </p>
                            <ul>
                                <li>PlayStation Gaming</li>
                                <li>Video Games Renting,</li>
                                <li>PlayStation Repairs,</li>
                                <li>Movies</li>
                                <li>PC and laptop Games
                                    installation.</li>
                                <li>Software Application installation.</li>
                            </ul>
                        </div>
                    </div>

                </div>}
                { generalInfo.homeSelectedIndex === 1 && <Movies /> } 
                { generalInfo.homeSelectedIndex === 2 && <Games /> } 


            </HeaderFooter>
        </>
    );
}

export default Home;
