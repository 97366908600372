import { useLoginContext } from "../providers/loginProvider";

function LoyaltyPoints() {
  const { user } = useLoginContext();

  return (
    <>
      <div className="row  p-0 m-0 w-100  p-2 h-100 bg-warnin">
        <div
          className="row m-0 p-0 d-flex align-items-center justify-content-center"
          style={{ alignContent: "flex-start" }}
        >
          <h4 className="text-center m-3">Loyalty Points</h4>
          <div className="col-xs-12 col-sm-9 col-md-8 col-lg-4 bg-white shadow rounded">
            <div className="mb-4 loyalty" style={{ marginTop: "5vw"}}>
              <div className="text-center">
                <img
                  src={"images/" + user.image}
                  alt="avatar"
                  className="rounded-circle img-fluid"
                  style={{ width: "150px" }}
                />
                <h5 className="m-3">
                  {user.username + " , " + user.othernames}
                </h5>
                <p className="text-muted m-3">{user.email}</p>
                <h5 className=" m-3" style={{color:"#ffa500"}}>{user.phone}</h5>
                <p className="text-dark m-3">
                  <span className="" style={{color:"#ffa500"}}>{user.address} , </span>{" "}
                  {user.city}
                </p>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-9 col-md-8 col-lg-7 bg-white shadow  m-2 rounded " style={{minHeight:"430px"}}>
            <div className="row d-fle align-items-center justify-content-center pt-4 m-0 w-100">
              <div
                className="card col-xs-12 col-sm-12 col-md-5 col-lg-5 m-2 bg-dark shadow "
                style={{ height: "12rem" }}
              >
                <div className="card-body text-center">
                  <h5 className="card-title text-warning">Points</h5>
                  <i className="fs-1 bi-cash-coin text-success"></i><span className="text-white fs-1" > {user.points}</span>
                  <p className="card-text text-white">
                   avialable Point you can redeem
                  </p>
                 
                </div>
              </div>
              <div
                className="card  col-xs-12 col-sm-12 col-md-5 col-lg-5 m-2 shadow bg-warning"
                style={{ height: "12rem" }}
              >
                 <div className="card-body text-center">
                  <h5 className="card-title text-dark">Redeemed Points</h5>
                  <i className="fs-1 bi-database-fill-down text-success"></i><span className="text-white fs-1" > {user.redeem}</span>
                  <p className="card-text">
                  This are the Points you have redeemed
                  </p>
                 
                </div>
              </div>
              <div
                className="card  col-xs-12 col-sm-12 col-md-12 col-lg-12 m-auto mt-4 shadow-lg text-center "
                
              >
                <p>you can redeem your points to rent games</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoyaltyPoints;
