import { useEffect, useState } from "react";
import DataTable from "react-data-table-component"
import { useCardDetailContext } from "../providers/cardDetailProvider";
import { useMessageBoxContext } from "../providers/messageBoxProvider";
import { useProductsContext } from "../providers/productsProvider";
import AdminProductCard from "./AdminProductCard";
import { useLoginContext } from "../providers/loginProvider";
import { useGeneralInfoContext } from "../providers/genaralInfoProvider";
import { useOrderContext } from "../providers/orderProvider";
import OrderCard from "./orderCard";




function AdminSales(){

  const {messageBox, setMessageBox} = useMessageBoxContext()
  const { setProducts} = useProductsContext()
  const {order,setOrder,orderItems,setOrderItems} = useOrderContext()
  const {generalInfo} = useGeneralInfoContext()


  const handleViewSale = (row) => {
    fetch("/getOrderItems", {
      method: "POST",
      body:JSON.stringify({id:row.id})
    })
      .then((res) => res.json())
      .then((data) => {
       if(data.error===0){
        setProducts(data.products)
        setOrder({...order,visible:true,detail:row})
       }else{
        setMessageBox({...messageBox, visible:true ,message:"something went wrong"})
       }
      });
    }
  

 
  
  const columns =  [
    {
      name:"Action",
      button:true,
      cell: (row) =>(
        <>
        <button className="btn btn-dark me-1  bi-display "  onClick={() => handleViewSale(row)}> View</button>
        </>
      )
  
    },
    {
      name: "Order ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Order By",
      selector : row => row.othernames,
    },
    {
      name: "Sub Total",
      selector: (row) => row.subtotal,
      sortable:true
    },
   
    {
      name : "Tax",
      selector : row => row.tax,
        sortable :true,
    },
    {
      name: "Total",
      selector : row => row.subtotal + row.tax,
        sortable: true,
      },
    {
      name : "Order Date",
      selector : row => row.orderdate,
        sortable :true,
    },
      {
      name : "Status",
      cell: (row) =>(
        <>
        {row.paid === 1 ? <div className="text-success">Paid</div> :  <div className="text-danger"> Not Paid</div>}
        </>
      )
    },
     
       
  ]

    useEffect(() => {
        fetch("/getOrders", {
          method: "POST",
          body:JSON.stringify({user:"admin",status:0})
        })
          .then((res) => res.json())
          .then((data) => {
            setOrderItems(data)
          });
      }, [generalInfo.refresh]);

    return (
        <>
          
        <div className="h-auto w-100 m-0 p-0">
       
        <div className="w-100">
                <DataTable
                title="Sales"
                columns={columns}
                data={orderItems}
                dense
                pagination
                striped
                theme="solarized"
                />
                
            </div>
            </div>
        </>
    )
}

export default AdminSales;