import { useState,useContext,createContext} from "react";


const CategoryContext = createContext();
function CategoryProvider({children}) {

    const [categories, setCategories] = useState([])
 
    return (
           <CategoryContext.Provider value={{ categories,setCategories }}>
                {children}
            </CategoryContext.Provider>
    )
}

export const useCategoryContext = () => useContext(CategoryContext);
export default CategoryProvider;