import { Controller, useController, useForm } from "react-hook-form";
import { useCartContext } from "../providers/cartProvider";
import { useLoginContext } from "../providers/loginProvider";
import { useMessageBoxContext } from "../providers/messageBoxProvider";
import "./product.css";
import { useEffect, useState } from "react";
import { useGeneralInfoContext } from "../providers/genaralInfoProvider";
import Process from "./process";

function Cart() {

  const { cart, setCart, cartItems, setCartItems } = useCartContext();
  const { messageBox, setMessageBox } = useMessageBoxContext();
  const { user,setUser } = useLoginContext();
  const {generalInfo, setGeneralInfo} = useGeneralInfoContext()
  const [isPay,setIsPay] = useState(false)
  const {
    register,
    control,
    formState: { errors },
    getValues,
    handleSubmit
  } = useForm({ mode: "all" });
  const{field} = useController({name:"points",control})

  const handleRemoveItem = (item) => {
    setCartItems(cartItems.filter((product) => product !== item));
  };

  let pointsused = 0
  let counttotal = 0

  cartItems.some((item) => {
     counttotal += item.total;
  });

useEffect(() => {
  setCart({...cart,subtotal:counttotal})
},[])

  const handlePointsOnChange = (value) => {
    pointsused = value
    if(pointsused !== ""){
    if(pointsused<=user.points){
      counttotal -= pointsused
    }
    }
   
    setCart({...cart,subtotal:counttotal})
    
  }

  let tax = Math.floor(cart.subtotal * 0.06);

  const handleOnSubmit = (data) => {
    if(data.points !== ""){
    
        pointsused=data.points
     
    }
    let items = []
    cartItems.some((item,index) => {
      let pickdate = item.pickupdate.split("/").reverse().join("-")
          items[index] ={ id:item.id,pickupdate:pickdate,returndate:item.returndate,days:item.days}
      })

    if (cartItems.length > 0) {
     const formdata = {
        subtotal: cart.subtotal,
        tax:tax,
        pointsused:pointsused,
        userid:user.userid,
        paid:isPay,
        items:items
      }
      setGeneralInfo({...generalInfo, isProcessing:true})
     fetch("/addOrder", {
        method: "POST",
        headers:{
          "Content-Type":"application/json"
        },
        body: JSON.stringify(formdata)
      })
        .then((res) => res.json())
        .then((data) => {
          if(data.error === 0){
            setGeneralInfo({...generalInfo, isProcessing:false})
            setCartItems(cartItems.filter((product) => product === ""));
            setCart({ ...cart, visible: false })
            setGeneralInfo({...generalInfo,refleshOrder:Math.floor(Math.random()*10000)})
            setUser({...user,points:data.points,redeem:data.redeem})
            setMessageBox({
              ...messageBox,
              visible: true,
              message: "Your order has been  recieved ",
            });
          }else{
            setMessageBox({
              ...messageBox,
              visible: true,
              message: "Some thing went wrong ",
            });
          }
         
        });
    } else {
      setMessageBox({
        ...messageBox,
        visible: true,
        message: "your cart is Empty ",
      });
    }
    setTimeout(() => {
      setMessageBox({
        ...messageBox,
        visible: false,
        message: "",
      });
    }, 3000);
  };

  return (
    <>
      <section
        className="section-products d-flex align-items-center justify-content-center shadow  m-0 p-0"
        style={{ zIndex: "2" }}
      >
        <div className="container  h-100 overflow-scroll">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col">
              <div className="card">
                <div className="card-body p-4 bg-whit rounded shadow">
                  <div className="row">
                    <div
                      className="col-sm-12 col-md-8 col-lg-7  shadow overflow-hidden"
                      style={{ height: "600px" }}
                    >
                      <h5 className="mb-3">
                        <button
                          className="btn  bg-warning text-dark"
                          onClick={() => setCart({ ...cart, visible: false })}
                        >
                          <i className="bi bi-arrow-left me-2 "></i>Continue
                          shopping
                        </button>
                      </h5>
                      <hr />

                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <div>
                          <p className="mb-1">Shopping cart</p>
                          <p className="mb-0">
                            You have {cartItems.length} items in your cart
                          </p>
                        </div>
                        <div>
                          <p className="mb-0">
                            <span className="text-muted">Sort by:</span>{" "}
                            <a href="#!" className="text-body">
                              price <i className="fas fa-angle-down mt-1"></i>
                            </a>
                          </p>
                        </div>
                      </div>
                      <div className=" h-75 overflow-scroll">
                        {cartItems.map((item, index) => (
                          <div className="card mb-3" key={item.id}>
                            <div className="card-body">
                              <div className="d-flex justify-content-between">
                                <div className="d-flex flex-row align-items-center">
                                  <span className="" style={{ width: "20px" }}>
                                    {index + 1}
                                  </span>
                                  <div>
                                    <img
                                      src={"images/" + item.image}
                                      className="rounded-3"
                                      alt="Shopping item"
                                      style={{ height: "55px", width: "40px" }}
                                    />
                                  </div>
                                  <div className="ms-3">
                                    <h5>{item.name}</h5>
                                    <p className="small mb-0">{item.date}</p>
                                  </div>
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                 
                                  <div className="ms-3">
                                    <h6>@ {item.price } Per Day for {item.days} Days</h6>
                                    <p className="small mb-0 text-success">Pick UP Date {item.pickupdate}</p>
                                    <p className="small mb-0 text-danger">Return Date {item.returndate.split("-").reverse().join("/")}</p>
                                  </div>
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                  <div style={{ width: " 50px" }}></div>
                                  <div style={{ width: "80px" }}>
                                    <h5 className="mb-0">Total {item.total}</h5>
                                  </div>
                                  <a
                                    style={{ color: "#cecece" }}
                                    onClick={() => handleRemoveItem(item)}
                                  >
                                    <i className="bi bi-trash fs-3 ms-2 text-danger"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="col-lg-5">
                      <div className="card bg-dark text-white rounded-3">
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center mb-1">
                            <h5 className="mb-0">Payment Details</h5>
                            <img
                              src={"images/" + user.image}
                              className="img-fluid rounded-3"
                              style={{ width: "200px", height: "180px" }}
                              alt="Avatar"
                            />
                          </div>

                          <form className="mt-1" onSubmit={handleSubmit(handleOnSubmit)}>
                            <div className="form-check m-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                onChange={(e) => {setIsPay(getValues("paynow"))}}
                                {...register("paynow")}
                              />
                              <label className="form-check-label" htmlFor="paynow">
                                Pay Now
                              </label>
                            </div>
                            <div>
                             {!isPay && <div className="bg-white " style={{position:"absolute",width:"100%",height:"12%",opacity:"0.1"}}> </div>}
                              <div className="form-outline form-white mb-2">
                                <label className="form-label" htmlFor="typeName">
                                  Enter M-pesa Number
                                </label>
                                <input
                                  type="text"
                                  className="form-control "
                                  placeholder="Phone Number"
                                  {...register("phone")}
                                />
                                <p className="text-danger fs-6">{errors.phone?.message}</p>
                              </div>
                              <div className="row d-flex  form-white mb-2">
                                <div className=" col-xs-5 col-sm-5 co-md-5 col-lg-5 mt-2">
                                  <label className="form-label" htmlFor="typeName">
                                    {" "}
                                    loyalty Points{" "}
                                    <span className="text-warning fs-5">
                                      {" "}
                                      {user.points}
                                    </span>{" "}
                                  </label>
                                </div>
                                <div className=" col-xs-7 col-sm-7 co-md-7 col-lg-7 ">
                                  
                                       <input
                                       type="text"
                                       className="form-control "
                                       placeholder="Redeem Points"
                                       {...register("points",{
                                        pattern: {
                                          value: /^[0-9]+$/,
                                          message:
                                            "points number can only be numbers",
                                        },
                                       })}
                                       value={field.value}
                                       onChange={(e) => {
                                         field.onChange( () => 
                                        
                                         { 
                                          pointsused = field.value
                                          if(pointsused > user.points){
                                          setMessageBox({...messageBox,visible:true,message:"your can not use more points than you have"})
                                          return field.value=0
                                          }else{
                                            return field.value=e.target.value
                                          }
                                          
                                        }
                                         )
                                         handlePointsOnChange(e.target.value)
                                       }}
                                       />
                                   
                                   
                                  <p className="text-danger " style={{fontSize:"12px"}}>{errors.points?.message}</p>
                                </div>
                              </div>
                            </div>
                          

                          <hr className="my-2" />

                          <div className="d-flex justify-content-between">
                            <p className="mb-2">Subtotal</p>
                            <p className="mb-2">Ksh {cart.subtotal}</p>
                          </div>

                          <div className="d-flex justify-content-between">
                            <p className="mb-2">Shipping</p>
                            <p className="mb-2">Ksh 0.00</p>
                          </div>

                          <div className="d-flex justify-content-between mb-4">
                            <p className="mb-2">Total(Incl. taxes)</p>
                            <p className="mb-2">Ksh {tax}</p>
                          </div>

                          {generalInfo.isProcessing ?
                          <Process/>
                          :
                          <button
                            type="submit"
                            className="btn btn-warning btn-block w-100"
                          >
                            <div className="d-flex justify-content-between">
                              <span className="me-2">Ksh {cart.subtotal + tax}</span>
                              <span>
                                Checkout{" "}
                                <i className="bi bi-arrow-right ms-2"></i>
                              </span>
                            </div>
                          </button>}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Cart;
