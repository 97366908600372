import { useState } from "react";

function DropDown({items, label,onChange }) {
  
  return (
    <>
      <div className="dropdown ms-1 me-1 m-0 p-0" style={{width:"110px"}}>
        <button
          type="button"
          className="btn btn-secondary  dropdown-toggle w-100"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {label + "  "}
        </button>
        <ul className="dropdown-menu   bg-dark">
          {items.map((item, index) => (
            <li
            key={index}
              onClick={(e) => {onChange(item)
              }}
            >
              <a className="dropdown-item text-white" href="#" id={item}>
                {item}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default DropDown;
