import { useEffect, useState } from "react";
import { Controller, useController, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import HeaderFooter from "./headerfooter";
import Process from "./process"
import Select from "react-select";
import { useGeneralInfoContext } from "../providers/genaralInfoProvider";

function Signup() {
  const { generalInfo, setGeneralInfo } = useGeneralInfoContext();
  const options = [
    { value: "Games", label: "Games" },
    { value: "Movies", label: "Movies" },
  ];
  useEffect(() => {
    setGeneralInfo({ ...generalInfo, isHomePage: false });
  }, []);

  const navigate = useNavigate();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({ mode: "all" });
  const [alert, setAlert] = useState({
    visible: false,
    message: "",
    color: "danger",
  });
 
  const onSubmit = (formdata) => {
    formdata.preference = formdata.preference.map((p) => p.value);

    setGeneralInfo({ ...generalInfo, isProcessing: true });
    fetch("/addUser", {
      method: "POST",
      body: JSON.stringify(formdata),
    })
      .then((res) => res.json())
      .then((data) => {
        setGeneralInfo({ ...generalInfo, isProcessing: false });
        window.scrollTo(0,0)
        if (data.error === 0) {
          setAlert({
            ...alert,
            message: "registration successful\n\nVerification email has been sent to your email account",
            color: "success",
            visible: true,
          });
          setTimeout(() => {
            setAlert({ ...alert, visible: false });
            navigate("/Login");
          }, 2000);
        } else if (data.error === 1) {
          setAlert({
            ...alert,
            message: "user with email " + formdata.email + " already  exist",
            color: "danger",
            visible: true,
          });
        } else if (data.error === 2) {
          setAlert({
            ...alert,
            message: "something went wrong",
            color: "danger",
            visible: true,
          });
        }
        setTimeout(() => {
          setAlert({ ...alert, visible: false });
        }, 4000);
      });
  };

  return (
    <>
      <HeaderFooter>
        <div
          className="col-sm-8 col-md-7 col-lg-6 col-xl-5 login ms-0 me-0 "
          style={{ height: "auto", marginTop: "5vh", marginBottom: "5vh" }}
        >
          <form
            className=" p-4  rounded  bg-dark m-0"
            onSubmit={handleSubmit(onSubmit)}
          >
            <h3
              className="text-white mb-0 pb-0 pb-md-0 mb-md-1 mt-0 text-center"
              style={{ borderRadius: "5px" }}
            >
              Signup
            </h3>
            {alert.visible && (
              <div
                className={
                  "alert  alert-info  fade show border border-" +
                  alert.color +
                  " text-" +
                  alert.color
                }
                role="alert"
              >
                <button
                  type="button"
                  className="btn text-danger  float-end"
                  style={{ position: "relative", top: "-10px" }}
                  onClick={(e) => {
                    setAlert({ ...alert, visible: false });
                  }}
                >
                  X
                </button>
                <span className={alert.color}>{alert.message}</span>
              </div>
            )}

            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="form-outline">
                  <label className="form-label text-white" htmlFor="surname">
                    SurName
                  </label>
                  <input
                    type="text"
                    name="surname"
                    className="form-control form-control-md"
                    {...register("surname", {
                      required: "Surname is required",
                      minLength: {
                        value: 3,
                        message: "Name can NOT be less  than three characters",
                      },
                      maxLength: {
                        value: 15,
                        message: "Name can NOT excede 15 characters",
                      },
                      pattern: {
                        value: /[a-zA-Z]+/,
                        message: "Name can only be letter",
                      },
                    })}
                  />
                  <p className="text-danger" style={{ fontSize: "13px" }}>
                    {errors.surname?.message}
                  </p>
                </div>
              </div>
              <div className="col-md-6 mb-1">
                <div className="form-outline">
                  <label
                    className="form-label text-white"
                    htmlFor="othersNames"
                  >
                    Other Names
                  </label>
                  <input
                    type="text"
                    name="otherNames"
                    className="form-control form-control-md"
                    {...register("othernames", {
                      required: "Name is required",
                      minLength: {
                        value: 3,
                        message: "Name can NOT be less  than three characters",
                      },
                      maxLength: {
                        value: 15,
                        message: "Name can NOT excede 15 characters",
                      },
                      pattern: {
                        value: /[a-zA-Z]+/,
                        message: "Name can only be letter",
                      },
                    })}
                  />
                  <p className="text-danger" style={{ fontSize: "13px" }}>
                    {errors.othernames?.message}
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-1 d-flex align-items-center">
                <div className="form-outline w-100">
                  <label htmlFor="preference" className="form-label text-white">
                    Preference
                  </label>
                  <Controller
                    control={control}
                    name="preference"
                    rules={{ required: "preference" }}
                    render={({
                      field: { value, onChange, name, ref, ...field },
                    }) => {
                      return (
                        <Select
                          name={name}
                          ref={ref}
                          isMulti
                          placeholder="Select preference"
                          className="form-control form-control-md p-0 "
                          options={options}
                          value={value}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          onChange={(option) => {
                            onChange(option);
                          }}
                          {...field}
                        />
                      );
                    }}
                  />

                  <p className="text-danger" style={{ fontSize: "13px" }}>
                    {errors.preference?.message}
                  </p>
                </div>
              </div>
              <div className="col-md-6 mb-1">
                <h6 className="mb-3 pb-1  text-white">Gender: </h6>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gender"
                    id="femaleGender"
                    value="Female"
                    {...register("gender", {
                      required: "Gender is required",
                    })}
                  />
                  <label
                    className="form-check-label text-white"
                    htmlFor="femaleGender"
                  >
                    Female
                  </label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gender"
                    id="maleGender"
                    value="Male"
                    {...register("gender", {
                      required: "Gender is required",
                    })}
                  />
                  <label
                    className="form-check-label text-white"
                    htmlFor="maleGender"
                  >
                    Male
                  </label>
                </div>
                <p className="text-danger" style={{ fontSize: "13px" }}>
                  {errors.gender?.message}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="form-outline">
                  <label className="form-label text-white" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="form-control form-control-md"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "invalid email preference ",
                      },
                    })}
                  />
                  <p className="text-danger" style={{ fontSize: "13px" }}>
                    {errors.email?.message}
                  </p>
                </div>
              </div>
              <div className="col-md-6 mb-1">
                <div className="form-outline">
                  <label className="form-label text-white" htmlFor="phone">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    className="form-control form-control-md"
                    {...register("phone", {
                      required: "Phone number id required",
                      minLength: {
                        value: 10,
                        message: "Phone number should be 10 characters",
                      },
                      maxLength: {
                        value: 10,
                        message: "Phone number should be 10 characters",
                      },
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Phone number can only numbers",
                      },
                    })}
                  />
                  <p className="text-danger" style={{ fontSize: "13px" }}>
                    {errors.phone?.message}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="form-outline">
                  <label className="form-label text-white" htmlFor="password">
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    className="form-control form-control-md"
                    {...register("password", {
                      required: "password is required",
                      minLength: {
                        value: 8,
                        message: "password MUST be atleast 8 characters",
                      },
                      maxLength: {
                        value: 16,
                        message: "password should NOT excede 16 characters",
                      },
                      pattern: {
                        value:
                          /^(?=.*[0-9])(?=.*[!@#$%^&*.,])[a-zA-Z0-9!@#$%^&*.,]{8,16}$/,
                        message:
                          "password should contain atleast 1 uppercase, 1 lowercase, 1 number and 1 special character",
                      },
                    })}
                  />
                  <p className="text-danger" style={{ fontSize: "13px" }}>
                    {errors.password?.message}
                  </p>
                </div>
              </div>
              <div className="col-md-6 mb-1">
                <div className="form-outline">
                  <label
                    className="form-label text-white"
                    htmlFor="confirmPassword"
                  >
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    name="confirmPassword"
                    className="form-control form-control-md"
                    {...register("confirmpassword", {
                      validate: (match) => {
                        const password = getValues("password");
                        return match === password || "Password DON'T match";
                      },
                    })}
                  />
                  <p className="text-danger" style={{ fontSize: "13px" }}>
                    {errors.confirmpassword?.message}
                  </p>
                </div>
              </div>
            </div>

           { generalInfo.isProcessing ? <Process /> :
           <button
              className="btn btn-warning text-dark w-100 m-0"
              type="submit"
            >
              Signup
            </button>}
            <p className="mt-2 text-white">
              Aready have an account?{" "}
              <a
                href="#"
                className="text-info fw-bold"
                onClick={() => navigate("/Login")}
              >
                Log In
              </a>
            </p>
          </form>
        </div>
      </HeaderFooter>
    </>
  );
}

export default Signup;
