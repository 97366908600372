import HeaderFooter from "./headerfooter";
import { useEffect, useRef, useState } from "react";
import { useGeneralInfoContext } from "../providers/genaralInfoProvider";
import Card from "./card";
import ReactPaginate from "react-paginate";
import DropDown from "./dropdown";
import Process from "./process";
import CardPlaceholder from "./cardPlaceholder";

function Movies(props) {
  const { generalInfo, setGeneralInfo } = useGeneralInfoContext();
  const [movies, setMovies] = useState([]);
  const [movieSearch, setMovieSearch] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [pageItems, setPageItems] = useState([]);
  const [count, setCount] = useState(0)
  const search = useRef();
  const fetchRef = useRef(false);

  const [filter, setFilter] = useState({type:"All",genre:"All",year:"All"});
  const [genreItems, setGenreItems] = useState([]);
  const [yearItems, setYearItems] = useState([]);
  const [orderby, setOrderby] = useState("Latest");
  const placeHolder = [1,1,1,1,1,1]

  const fetchMovies = (offset) => {
    setGeneralInfo({ ...generalInfo, isProcessing: true });
    fetch("/getMovies", {
      method: "POST",
      body: JSON.stringify({ offset: offset }),
    })
      .then((res) => res.json())
      .then((data) => {
        setGeneralInfo({ ...generalInfo, isProcessing: false });
        setMovies((prev) => [...prev, ...data.movies]);
        setMovieSearch((prev) => [...prev, ...data.movies]);
        if (offset === 0) {
          setPageItems(data.movies);
        }
      });
  };

  useEffect(() => {
    setGeneralInfo({ ...generalInfo, isProcessing: true });
    fetch("/getCount",{
      method:"POST",
      body:JSON.stringify({productType:"Movies"})
    })
    .then( res => res.json() )
    .then( data => {
      setGeneralInfo({ ...generalInfo, isProcessing: false });
      setCount(data.count)
     } )
 },[])

  useEffect(() => {
    let genre = movies.map((movie) => movie.sub_category)
    genre.unshift("All")
    setGenreItems( Array.from(new Set(genre)) )
    let year = movies.map((movie) => new Date(movie.releasedate).getFullYear() )
    year.unshift("All")
    setYearItems(
      year.filter( (movie,index,array) => index === array.indexOf(movie) )
    );
  }, [movies]);


  useEffect(() => {
    let i = 0;
    let offset = 0;
    while (i <count) {
      fetchMovies(offset);
      i += 18;
      offset = i;
    }
  }, [count]);

  const handleSeachClick = (e) => {
    let val = search.current.value;
    if (val === "") {
      setMovieSearch(movies);
      setPageItems(movies.slice(0, 18));
    } else {
      const newData = movies.filter((movie) => {
        return movie.name.toLowerCase().includes(val.toLowerCase());
      });
      setMovieSearch(newData);
      setPageItems(newData.slice(0, 18));
    }
  };

  const handlePageClick = (page) => {
    const start = page.selected * 18;
    const end = start + 18;
    setPageItems(movieSearch.slice(start, end));
  };

  const handleTypeChange = (e) => {
    setFilter({...filter,type:e});
    // handleFilterChange(e)
  };

  const handleGenreChange = (e) => {
    setFilter({...filter,genre:e});
    // handleFilterChange(e)
  };

  const handleYearChange = (e) => {
    setFilter({...filter,year:e});
    // handleFilterChange(e)
  };

  useEffect( () => {
    setMovieSearch(movies)
    if (filter.type !== "All") {
      setMovieSearch(prev => prev.filter((movie) => movie.category === filter.type));
    }
    if (filter.genre !== "All") {
      setMovieSearch(prev => prev.filter((movie) => movie.sub_category === filter.genre));
    }
    if (filter.year !== "All") {
      setMovieSearch(prev => prev.filter((movie) => new Date(movie.releasedate).getFullYear() === filter.year));
    }
   
  },[filter])

  useEffect(() => {
    setPageItems(
      movieSearch.slice(0, 18)
    );
  },[movieSearch])

  const handleOrderbyChange = (e) => {
    setOrderby(e);
  };

  return (
    <>
      <div className="w-100 bg-dark">
        <div
          className="bg-dark homecontent  p-1 overflow-scroll"
          style={{ height: "60vw" }}
        >
          <h3
            className="w-100 text-center "
            style={{ textShadow: "2px 2px  #000000" }}
          >
            Latest Movies & TV Shows
          </h3>
          <div
            className="row d-flex align-items-center flex-direction-row justify-content-center m-auto"
            style={{ height: "40px" }}
          >
            <form
              className="d-flex col-sm-10 col-md-8 m-0 p-0 mb-2"
              role="search"
            >
              <input
                className="form-control  m-0 "
                type="search"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Search For Movies ..."
                aria-label="Search"
                ref={search}
              />
              <button
                className="btn bg-warning text-dark col-sm-2 ms-1"
                type="button"
                onClick={handleSeachClick}
              >
                Search
              </button>
            </form>
            <div></div>
            <p
              className="d-flex col-sm-10 col-md-8 m-0 p-0 mb-2 bg-light d-none"
              style={{ height: "400px", zIndex: "5" }}
            >
              ghjkj
            </p>
          </div>
          <div className="row d-fle align-items-center justify-content-center mt-1 bg-dark">
            <div className="dropmenu mb-2">
              <div
                className="ps-1 pb-1 text-warning "
                style={{ textShadow: "2px 2px #000000" }}
              >
                Type :
              </div>
              <DropDown
                items={["All","Movies", "TV Shows"]}
                label={filter.type}
                onChange={handleTypeChange}
              />
            </div>
            <div className="dropmenu mb-2">
              <div
                className="ps-1 pb-1 text-warning"
                style={{ textShadow: "2px 2px #000000" }}
              >
                Genre :
              </div>
              <DropDown
                items={genreItems}
                label={filter.genre}
                onChange={handleGenreChange}
              />
            </div>
            <div className="dropmenu mb-2 ">
              <div
                className="ps-1 pb-1 text-warning"
                style={{ textShadow: "2px 2px #000000" }}
              >
                Year :
              </div>
              <DropDown
                items={yearItems}
                label={filter.year}
                onChange={handleYearChange}
              />
            </div>
            <div className="dropmenu mb-2 ">
              <div
                className="ps-1 pb-1 text-warning"
                style={{ textShadow: "2px 2px #000000" }}
              >
                Order By :
              </div>
              <DropDown
                items={["Alphabetic", "Latest", "Oldest"]}
                label={orderby}
                onChange={handleOrderbyChange}
              />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center mt-2 ">
            {movieSearch.length === 0 ? (
              <h4>No Movies to Display</h4>
            ) : (
              <ReactPaginate
                previousLabel={"Prev"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={Math.ceil(movieSearch.length / 18)}
                pageRangeDisplayed={2}
                marginPagesDisplayed={3}
                containerClassName=" pagination "
                pageClassName="page-item"
                pageLinkClassName="page-link text-dark"
                previousClassName="page-item"
                previousLinkClassName="page-link text-warning bg-dark"
                nextClassName="page-item"
                nextLinkClassName="page-link text-warning bg-dark"
                activeClassName=""
                activeLinkClassName="text-dark bg-warning"
                onPageChange={handlePageClick}
              />
            )}
          </div>
          <div className="row d-flex align-items-center flex-direction-row justify-content-center">
            {pageItems.map((movie) => (
              <Card product={movie} key={movie.id} />
            ))}
            {generalInfo.isProcessing && 
              placeHolder.map(() => (
                <CardPlaceholder />
              ))

            }
          </div>
        </div>
      </div>
    </>
  );
}

export default Movies;
