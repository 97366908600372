import { useCardDetailContext } from "../providers/cardDetailProvider";
import { useLoginContext } from "../providers/loginProvider";
import "./card.css";

function Card({ product}) {
  const { cardDetail, setCardDetail } = useCardDetailContext();
  const {user} = useLoginContext()
  const imgpath = "images/" + product.image;
  let date = new Date(product.releasedate)

  const handleClick = () => {
    if(user !== null){
      if(user.isLoggedIn){
        setCardDetail({
          ...cardDetail,
          id: product.id,
          name: product.name,
          total:product.price,
          description: product.description,
          price: product.price,
          category:product.category,
          productType: product.product_type,
          image: product.image,
          date:date.toLocaleDateString(),
          visible: true,
        });
      }
    }
   
  };
  return (
    <>
      <div
        className="p-2 m-1 bg-white mt-1 shadow product"
        onClick={() => handleClick()}
      >
        <img
          src={imgpath}
          className="card-img-top p-1 align-center"
          alt="img"
          style={{ height: "75%" }}
        />
        <div className="">
          <h6 className="p-0 m-0 text-dark p-1 name">{product.name}</h6>
          <p className="p-0 m-0"  style={{color:"#ffa500"}}>{date.toLocaleDateString()}</p>
        </div>
      </div>
    </>
  );
}

export default Card;
