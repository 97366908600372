import { useEffect } from "react";
import { useLoginContext } from "../providers/loginProvider";
import { useProductsContext } from "../providers/productsProvider";
import { useMessageBoxContext } from "../providers/messageBoxProvider";
import { useOrderContext } from "../providers/orderProvider";
import { useGeneralInfoContext } from "../providers/genaralInfoProvider";
import DataTable from "react-data-table-component";
import OrderCard from "./orderCard";

function Orders(){

  const {user} = useLoginContext()
  const {setProducts} = useProductsContext()
  const {messageBox,setMessageBox} = useMessageBoxContext()
  const {order,setOrder,orderItems,setOrderItems} = useOrderContext()
  const {generalInfo, setGeneralInfo} = useGeneralInfoContext()

  const handleViewOrder = (row) => {
    setGeneralInfo({...generalInfo, isProccessing:true})
    fetch("/getOrderItems", {
      method: "POST",
      body:JSON.stringify({id:row.id})
    })
      .then((res) => res.json())
      .then((data) => {
        setGeneralInfo({...generalInfo, isProccessing:false})
       if(data.error===0){
        setProducts(data.products)
        setOrder({...order,visible:true,detail:row})
       }else{
        setMessageBox({...messageBox, visible:true ,message:"something went wrong"})
       }
      });
    }
  
  
 
  
  const columns =  [
    {
      name:"Action",
      button:true,
      cell: (row) =>(
        <>
        <button className="btn btn-dark me-1 fs-6 bi-display  text-warning"  onClick={() => handleViewOrder(row)}> View</button>
        </>
      )
  
    },
    {
      name: "Order ID",
      selector: (row) => row.id,
      sortable: true,
    },
   
    {
      name: "Amount",
      selector : row => row.subtotal + row.tax,
        sortable: true,
      },
    {
      name : "Order Date",
      selector : row => row.orderdate,
        sortable :true,
    },
      {
      name : "Payment",
      cell: (row) =>(
        <>
        {row.paid === 1 ? <div className="text-success">Paid</div> :  <div className="text-danger"> Not Paid</div>}
        </>
      )
    },
    {
      name : "Status",
      cell: (row) =>(
        <>
        {row.status === 1 ? <div className="bg-warning p-2 m-2 rounded" style={{width:"100px"}}>Pending</div> :  <div className="bg-success p-2 m-2 rounded"  style={{width:"100px"}}> Complete</div>}
        </>
      )
    },
     
       
  ]

    useEffect(() => {
        fetch("/getOrders", {
          method: "POST",
          body:JSON.stringify({user:"user"})
        })
          .then((res) => res.json())
          .then((data) => {
            setOrderItems(data)
          });
      }, [generalInfo.refreshOrder]);

  return(
  <>
  <div className="row  w-100  p-2 ">
    <div
      className="row m-0 p-0 d-flex align-items-center justify-content-center"
      style={{ alignContent: "flex-start" }}
    >
      <h4 className="text-center m-2">My Orders</h4>
      <div className="col-xs-12 col-sm-12 col-md-8 col-lg-4 bg-white shadow p-auto rounded " style={{height:"450px"}}>
        <div className="mt-4" >
          <div className="text-center bg-inf  ">
            <img
              src={"images/" + user.image}
              alt="avatar"
              className="rounded-circle img-fluid"
              style={{ width: "150px" }}
            />
            <h5 className="m-3">
              {user.username + " , " + user.othernames}
            </h5>
            <p className="text-muted m-3">{user.email}</p>
            <h5 className=" m-3" style={{color:"#ffa500"}}>{user.phone}</h5>
            <p className="m-3"><span className="" style={{color:"#ffa500"}}>{user.address} , </span> {user.city}</p>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-8 col-lg-7 bg-white shadow overflow-scroll m-2 rounded" style={{height:"450px"}}>
        <div className="w-100">
                <DataTable
                title="Orders"
                columns={columns}
                data={orderItems}
                dense
                pagination
                striped
                theme="solarized"
                />
                
            </div>
      </div>
    </div>
  </div>
</>
);
}

export default Orders;