import { useState,useContext,createContext} from "react";


const CartContext = createContext();
function CartProvider({children}) {

    const [cart, setCart] = useState({
        visible:false,
        title:"",
        subtotal:0,
       
    })
    const [cartItems, setCartItems] = useState([])

    return (
           <CartContext.Provider value={{ cart,setCart,cartItems,setCartItems }}>
                {children}
            </CartContext.Provider>
    )
}

export const useCartContext = () => useContext(CartContext);
export default CartProvider;