import { useState,useContext,createContext} from "react";


const AdminContext = createContext();
function AdminProvider({children}) {

    const [admins, setAdmins] = useState([])
 
    return (
           <AdminContext.Provider value={{ admins,setAdmins }}>
                {children}
            </AdminContext.Provider>
    )
}

export const useAdminContext = () => useContext(AdminContext);
export default AdminProvider;