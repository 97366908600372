import "./product.css";
import { useCardDetailContext } from "../providers/cardDetailProvider";
import { useCartContext } from "../providers/cartProvider";
import { useMessageBoxContext } from "../providers/messageBoxProvider";
import { useEffect, useState } from "react";
import { useController, useForm } from "react-hook-form";
import { useGeneralInfoContext } from "../providers/genaralInfoProvider";
import Process from "./process";

function ProductCard() {
  const { cardDetail, setCardDetail } = useCardDetailContext();
  const { cart, setCart, cartItems, setCartItems } = useCartContext();
  const { generalInfo, setGeneralInfo } = useGeneralInfoContext();
  const { messsageBox, setMessageBox } = useMessageBoxContext();
  const [game, setGame] = useState({
    avialable: true,
    today: new Date(),
    returndate: new Date(),
    newreturndate: new Date(),
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({ mode: "all" });
  const { field } = useController({ name: "date", control });

  const [alert, setAlert] = useState({
    visible: false,
    message: "",
  });

  const handleAddToCart = () => {
    if (cardDetail.productType === "Games") {
    /*  if (game.avialable) { */
        const id = cardDetail.id
        const itemExists = cartItems.some((item) => {
          if (item.id === id) {
            return true;
          }
        });

        if (!itemExists) {
          setCartItems([...cartItems, cardDetail]);

          setAlert({
            ...alert,
            message: "Item has been added to cart",
            visible: true,
          });
        } else {
          setAlert({
            ...alert,
            message: "Item is already in cart",
            visible: true,
          });
        }
        setTimeout(() => {
          setCardDetail({ ...cardDetail, visible: false });
        }, 3000);
     /* }*/
    }
  };

  useEffect(() => {
    setGeneralInfo({ ...generalInfo, isproccessing: true });
    fetch("/checkAvailability", {
      method: "POST",
      body: JSON.stringify({ id: cardDetail.id }),
    })
      .then((res) => res.json())
      .then((data) => {
        setGeneralInfo({ ...generalInfo, isproccessing: false });
        let d = new Date(data.today);
        if (data.error === 0) {
          d.setDate(d.getDate() + 1);
          let date = d
            .toLocaleDateString("en-GB", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .split("/")
            .reverse()
            .join("-");
          setGame({
            ...game,
            avialable: true,
            newreturndate: date,
            today: data.today,
          });
          d.setDate(d.getDate()+1)
          let pickdate = d.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }).split("/").reverse().join("-")
          setCardDetail({ ...cardDetail, returndate: pickdate,pickupdate: date.split("-").reverse().join("/")  });
          setValue("date", date);
        } else {
          d = new Date(data.returndate);
          d.setDate(d.getDate() + 1);
          let date = d.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
          d.setDate(d.getDate() + 1);
          let newdate = d
            .toLocaleDateString("en-GB", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .split("/")
            .reverse()
            .join("-");
          setGame({
            ...game,
            avialable: false,
            returndate: date,
            newreturndate: newdate,
            today: date,
          });
          setCardDetail({ ...cardDetail, returndate: newdate,pickupdate:date });
          setValue("date", newdate);
        }
      });
  }, []);

  return (
    <>
      <section className="section-products d-flex align-items-center justify-content-center shadow  m-0 p-0">
        <div className="row bg-white rounded shadow product-card p-2 ">
          <div className="  d-flex justify-content-end">
            <button
              type="button"
              className=" text-danger bg-light p-0 ps-2 pe-2"
              aria-label="Close"
              onClick={() => {
                setCardDetail({ ...cardDetail, visible: false });
              }}
            >X</button>
          </div>
          {alert.visible && (
            <div className="alert  alert-info  fade show" role="alert">
              <span className={alert.color + " fs-6"}>{alert.message}</span>
            </div>
          )}
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center justify-content-center  shadow">
            <img
              src={"images/" + cardDetail.image}
              alt="product"
              className="image shadow"
            />
          </div>
          <form
            className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
            onSubmit={handleSubmit(handleAddToCart)}
          >
            <h4 className="text-center mb-4">{cardDetail.name}</h4>
            <div className=" text-center mb-4">
              <h6>{cardDetail.type}</h6>
              {cardDetail.description}

              {cardDetail.productType === "Games" ?
              <h5 className="fs-6 m-2">
                Per Day {" "}
                <span className="text-success bi bi-cash m-2 fs-5">
                  {" "}
                  {cardDetail.price} Ksh
                </span>
              </h5>
              :
              <h5 className="fs-6 m-2">
                Cost  {" "}
                <span className="text-success bi bi-cash m-2 fs-5">
                  {" "}
                  {cardDetail.price} Ksh
                </span>
              </h5>
            } 
            </div>

            
            {cardDetail.productType === "Games" && 
            <div className="form-outline">
              <label className="form-label text-dark" htmlFor="date">
                Return Date
              </label>

              <input
                type="date"
                min={game.newreturndate}
                value={field.value}
                onKeyDownCapture={(e) => e.preventDefault()}
                onChangeCapture={(e) => {
                  setCardDetail({...cardDetail,pickupdate:e.target.value.split("-").reverse().join("/")})
                  let rd = new Date(e.target.value)
                  let td =  new Date(game.today)
                  let d = (rd.setHours(0,0,0,0) - td.setHours(0,0,0,0) ) / (1000 * 60 * 60 * 24) ;
                  if (d < 1) {
                    d = 1;
                  }
                  let total = d * cardDetail.price;
                  setGame({ ...game, day: d });
                  setCardDetail({
                    ...cardDetail,
                    returndate: e.target.value,
                    total: total,
                    days: d,
                  });
                }}
                className="form-control form-control-md"
                {...register("date", {
                  required: "Select return date",
                })}
              />

              <p className="text-danger" style={{ fontSize: "13px" }}>
                {errors.date?.message}
              </p>
              <div className="text-success  text-center m-1">
                {game.avialable
                  ? "Game is Available for Rent"
                  : "Game will be avialable on " + game.returndate}
              </div>
            </div>
           }
 {cardDetail.productType === "Games" && 
<h5 className="fs-6 m-2 text-center">
              Total Amount for {cardDetail.days} Days
              <span className="text-success m-2 fs-5">
                {" "}
                {cardDetail.total} Ksh
              </span>
            </h5>
            }
            <div className="d-flex align-items-center justify-content-center ">
              {generalInfo.isproccessing ? (
                <Process />
              ) :  (
                <>
                  {game.avialable ? (
                    <button
                      type="submit"
                      className={cardDetail.productType === "Games" ? "btn bg-dark btn-outline-light m-1 shadow" : "d-none"}
                    >
                      <i className="bi bi-cart me-1"></i>Add To Cart
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className={cardDetail.productType === "Games" ? "btn bg-warning  btn-outline-light m-1 shadow text-dark" : "d-none"}
                    >
                      <i className="bi bi-cart me-1"></i>Book
                    </button>
                  )}
                </> 
              )}
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default ProductCard;
