import { useForm } from "react-hook-form";
import HeaderFooter from "./headerfooter";
import { useGeneralInfoContext } from "../providers/genaralInfoProvider";
import { useEffect, useState } from "react";
import {useNavigate } from "react-router-dom";
import Process from "./process";

function ForgotPassword() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "all" });

  
  const [alert, setAlert] = useState({
    visible: false,
    message: "",
    color: "success",
  });

const navigate = useNavigate()
  const {generalInfo,setGeneralInfo} = useGeneralInfoContext()

  useEffect(() => {
    setGeneralInfo({...generalInfo, isHomePage:false})
  },[])

  const onSubmit = (data) => {
    setGeneralInfo({...generalInfo, isProccessing:true})
    fetch("/sendResetEmail", {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setGeneralInfo({...generalInfo, isProccessing:false})
        if(data.error == 0){
          setAlert({ ...alert, visible: true,message:" Reset email was sent to your email account" ,color:"success" });
          setTimeout(()=>{
            navigate("/Login")
          },2000)
        }else if(data.error == 1 ){
          setAlert({ ...alert, visible: true,message:"  email address you  entered does not exist in our system",color:"danger" });
        }else{
          setAlert({ ...alert, visible: true,message:" something when wrong",color:"danger" });
        }
      });
  };

  return (
    <>
      <HeaderFooter>
        <div
          className="login col-xs-10 col-sm-7 col-md-6 col-lg-5 col-xl-4 mb-3 p-2 bg-dark"
        >
          <div className="card-body">
            <div className="pt-4 pb-4">
              <h4 className="card-title text-center text-white pb-4 fs-5">
                Enter your email to reset Password
              </h4>
            </div>

            <div className="col-12">
              <p className="text-center small error" id="mainerror"></p>
            </div>

            <form
              className="row g-3 needs-validation p-2 shadow "
              autocomplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
                {alert.visible && (
              <div className={"alert  alert-info  fade show border border-" + alert.color + " text-" + alert.color} role="alert">
                <button
                  type="button"
                  className="btn text-danger  float-end"
                  style={{ position: "relative", top: "-10px" }}
                  onClick={(e) => {
                    setAlert({ ...alert, visible: false });
                  }}
                >
                  X
                </button>
                <span className={alert.color}>{alert.message}</span>
              </div>
            )}

              <div className="col-12">
                <label for="txtIdNumber" className="form-label text-white">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  {...register("email", {
                    required: "Please enter your email address",
                    maxlength: {
                      value: 100,
                      message: "Email can not excede 100 characters",
                    },
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "invalid email address ",
                    },
                  })}
                />
                <p className="text-danger">{errors.email?.message}</p>
              </div>

              <div className="col-12">
               { generalInfo.isProccessing ?
                <Process/>
               :<button
                  className="btn btn-warning text-dark w-100"
                  type="submit"
                  id="btnrequestreset"
                >
                  Reset Password
                </button>}
              </div>
            </form>
          </div>
        </div>
      </HeaderFooter>
    </>
  );
}

export default ForgotPassword;
