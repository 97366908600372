import { get, useForm } from "react-hook-form";
import { useOrderContext } from "../providers/orderProvider";
import { useLoginContext } from "../providers/loginProvider";
import { useMessageBoxContext } from "../providers/messageBoxProvider";
import "./product.css";
import { useState } from "react";
import { useGeneralInfoContext } from "../providers/genaralInfoProvider";
import { useProductsContext } from "../providers/productsProvider";
import Process from "./process";

function OrderCard() {

  const { order, setOrder, orderItems, setOrderItems } = useOrderContext();
  const { messageBox, setMessageBox } = useMessageBoxContext();
  const { products,setProducts } = useProductsContext();
  const {user} = useLoginContext()
  const {generalInfo, setGeneralInfo} = useGeneralInfoContext()
  const {
    register,
    formState: { errors },
    getValues,
  } = useForm({ mode: "all" });

  const handleRemoveItem = (item) => {
    setOrderItems(orderItems.filter((product) => product !== item));
  };

  const handleChangeStatus= (item) => {
    if(user.isAdmin){
      if(item.status){
        setMessageBox({...messageBox,visible:true,deleteIndex:0,message:"are you sure you want to change this item status to Picked ?"})
        if(messageBox.status ){
          setGeneralInfo({...generalInfo,isProccessing:true})
          let points = (item.price * item.days)/25
          fetch("/updateOrderItemStatus", {
            method: "POST",
            body: JSON.stringify({ id:item.id,orderid:item.orderid,points:points })
          })   
            .then((res) => res.json())  
            .then((data) => {
              setGeneralInfo({...generalInfo,isProccessing:false})
              if(data.error ===0){
                setGeneralInfo({...generalInfo,refleshOrder:Math.floor(Math.random()*10000)})
                setMessageBox({...messageBox,visible:true,deleteIndex:-1,message:"item status has been changed to Picked"})
              }else{
                setMessageBox({...messageBox,visible:true,deleteIndex:-1,message:"something when wrong",color:"danger"})
              }
            })
        }
        
      }else{
        setMessageBox({...messageBox,visible:true,deleteIndex:-1,message:item.name + " for this order has already been picked"})
      }
    }
    setTimeout(() => {
      setMessageBox({ ...messageBox,deleteIndex:-1, visible:false,messagee: ""})
    },4000)
  };

  const handleCompleteOrder = () => {
    setGeneralInfo({...generalInfo, isProccessing:true})
    let points = (order.detail.subtotal - order.detail.pointsused)/25
    fetch("/completeOrder", {
        method: "POST",
        body: JSON.stringify({ orderId : order.detail.id,userid:order.detail.userid,points:points })
      })   
        .then((res) => res.json())  
        .then((data) => {
          setGeneralInfo({...generalInfo, isProccessing:false})
         if(data.error ===0){
          setGeneralInfo({...generalInfo,refreshOrder:Math.floor(Math.random()*10000)})
          setMessageBox({...messageBox, visible:true ,message:"Order has been completed",color:"success"})
          setOrder({...order,visible:false})
         }else{
          setMessageBox({...messageBox, visible:true ,message:"something went wrong",color:"danger"})
         }
         setTimeout(() => {
          setMessageBox({...messageBox, visible:false})
         },3000)
        })
  }

  const handleCancelOrder = () => {
    setGeneralInfo({...generalInfo, isProccessing:true})
    fetch("/cancelOrder", {
        method: "POST",
        body: JSON.stringify({ orderId : order.detail.id })
      })   
        .then((res) => res.json())  
        .then((data) => {
          setGeneralInfo({...generalInfo, isProccessing:false})
          setOrder({...order,visible:false,detail:[]})
         if(data.error ===0){
          setGeneralInfo({...generalInfo,refreshOrder:Math.floor(Math.random()*10000)})
          setMessageBox({...messageBox, visible:true,deleteIndex:-1 ,message:"Order has been cancelled",color:"success"})
          setOrder({...order,visible:false})
         }else{
          setMessageBox({...messageBox, visible:true,deleteIndex:-1 ,message:"something went wrong",color:"danger"})
         }
         setTimeout(() => {
          setMessageBox({...messageBox, visible:false})
         },3000)
        })
  }

  return (
    <>
      <section
        className="section-products row d-flex align-items-center justify-content-center shadow  overflow-scroll  m-0 p-0"
        style={{ zIndex: "2",backgroundColor:"rgba(16,23,19,0.7)" }}
      >
        <div className="container col-sm-8 col-md-7 col-lg-7 overflow-scroll shadow" >
          <div className="row d-flex justify-content-center align-items-center h-100">
            
              <div className="card">
              <div className="  d-flex justify-content-end">
            <button
              type="button"
              className="btn-close text-danger"
              aria-label="Close"
              onClick={() => {
                setOrder({ ...order, visible: false })
              }}
            ></button>
            </div>
            <div className="">
              <span className="fs-4">Order No : {order.detail.id}</span>
              <span className="fs-6 text-warning"> {order.detail.surname} ,{order.detail.othernames} </span>
              <span className="fs-5">Subtotal : {order.detail.subtotal} </span>
              <span className="fs-5">Tax : {order.detail.tax} </span>
              <span className="fs-5 text-success">Total : {order.detail.subtotal + order.detail.tax} </span>
              <span className={order.detail.paid === 1 ? "fs-5 text-success" : "fs-5 text-danger"}>  {order.detail.paid === 1 ? "Paid" : "Not paid"} </span>
              </div>
                <div className="card-body p-2">
                
                    <div
                      className="  shadow overflow-hidden"
                      style={{ height: "500px" }}
                    >

                      <div className="  overflow-scroll p-1">
                        {products.map((item, index) => (
                          <div className="card mb-3">
                            <div className="card-body">
                              <div className="d-flex justify-content-between">
                                <div className="d-flex flex-row align-items-center">
                                  <span className="" style={{ width: "20px" }}>
                                    {index + 1}
                                  </span>
                                  <div>
                                    <img
                                      src={"images/" + item.image}
                                      className="rounded-3"
                                      alt="Shopping item"
                                      style={{ height: "55px", width: "40px" }}
                                    />
                                  </div>
                                  <div className="ms-3">
                                    <h5>{item.name}</h5>
                                    <p className="small mb-0">{new Date(item.releasedate).toLocaleDateString()}</p>
                                  </div>
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                 
                                 <div className="ms-3">
                                   <h6>@ {item.price } Per Day for {item.days} Days</h6>
                                   <p className="small mb-0 text-success">Pick UP Date {new Date(item.pickupdate).toLocaleDateString()}</p>
                                   <p className="small mb-0 text-danger">Return Date {new Date(item.returndate).toLocaleDateString()}</p>
                                 </div>
                               </div>
                                <div className="d-flex flex-row align-items-center">
                                  <div style={{ width: " 50px;" }}></div>
                                  <div style={{ width: "80px;" }}>
                                    <h5 className="mb-0">{item.price * item.days} Ksh</h5>
                                  </div>
                                
                                  {user.isAdmin === 1 &&
                                    <a  style={{ color: "#cecece;" }}
                                    onClick={(e) => handleChangeStatus(item)}
                                  >
                                    <i className={item.status ? "bi bi-hourglass-split fs-3 ms-2 text-warning" :"bi bi-check-lg fs-3 ms-2 text-success" }></i>
                                  </a>
                                }

                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
      
                </div>
                <div className="d-flex align-items-center justify-content-center ">
              { generalInfo.isProccessing ?
              
              <Process/>
              :<>
              <button
                className={user.isAdmin === 1  && order.detail.status === 1? "btn bg-dark btn-outline-light m-1 shadow" : "d-none"}
                onClick={(e) => handleCompleteOrder()}
              >
                <i className="bi bi-cart me-1"></i>Complete Order
              </button>
              <button className={user.isAdmin === 1 || order.detail.status === 0 ? "d-none" : "btn bg-danger  btn-outline-light m-1 shadow text-white"}
              onClick={(e) => handleCancelOrder()}
              >
                <i className="bi bi-close me-1"></i>Cancel Order
              </button>
              </>}
            </div>
            </div>
          </div>
         
        </div>
      </section>
    </>
  );
}

export default OrderCard;
