import { useLoginContext } from "../providers/loginProvider";
import { useNavigate } from "react-router-dom";
import "./admin.css";
import logo from "../assets/logo.png";
import { useState } from "react";
import AdminHome from "./adminHome";
import AdminProduct from "./adminProducts";
import MessageBox from "./messagebox";
import { useMessageBoxContext } from "../providers/messageBoxProvider";
import Processing from "./processing";
import { useGeneralInfoContext } from "../providers/genaralInfoProvider";
import AdminUser from "./AdminUser";
import AdminProvider from "../providers/adminProvider";
import AdminOrder from "./adminOrders";
import AdminSales from "./adminSales";
import OrderCard from "./orderCard";
import { useOrderContext } from "../providers/orderProvider";

function AdminDashboard() {
  const menuitem = ["Home", "Products", "Orders", "Sales", "Users"];
  const icons = [
    "bi-house",
    "bi-bag-check",
    "bi-table",
    "bi-cash-coin",
    "bi-people",
  ];
  const [selectedIndex, setselectedindex] = useState(0);
  const { messageBox } = useMessageBoxContext();
  const { generalInfo, setGeneralInfo } = useGeneralInfoContext();
  const { order } = useOrderContext();
  const [style, setStyle] = useState("menu");

  const { user, setUser } = useLoginContext();
  const navigate = useNavigate();
  
  if (!user.isLoggedIn && user.isAdmin) {
    navigate("/");
  }

  const onMenuClick = (index) => {
    setselectedindex(index);
  };

  const handleLogout = (e) => {
    fetch("/logout", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        localStorage.setItem("user",null)
        setUser(null);
        navigate("/Login");
      });
  };

  const handleMenuToggler = (e) => {
    if(style === "menu"){
      setStyle("menutoggle")
    }else{
      setStyle("menu")
    }
  }

  return (
    <>
      <div className=" p-0 m-0 overflow-hidden w-100" style={{height:"100vh"}}>
        <div className="d-flex m-0 p-0 main " style={{}}>
          <div className={style + " col-xs-12 col-sm-3  col-md-3 col-xl-2 p-0 m-0"} style={{zIndex:2}}>
          <button onClick={handleMenuToggler} style={{position:"absolute",left:"60px",top:"75px",backgroundColor:"rgb(16,23,19)"}} className=" p-1 ps-2 pe-2 ms-1 d-sm-none d-md-none d-lg-none d-xl-none d-xxl-none"><i className={style === "menu" ? "bi bi-menu-button-wide" : "bi bi-x-lg"}></i></button>
            <div className="d-flex flex-column align-items-center align-items-sm-start px-2 pt-2 text-white min-vh-100 m-0 p-0">
              <a
                href="/"
                className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none"
              >
                <h3></h3>
              </a>
              <ul
                className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start w-100 m-0 p-0"
                id="menu"
              >
                {menuitem.map((item, index) => (
                  <li
                    className={
                      selectedIndex === index
                        ? "nav-item bg-warning rounded w-100"
                        : "nav-item rounded w-100"
                    }
                    key={index}
                    onClick={(e) => onMenuClick(index, item)}
                  >
                    <a
                      href="#"
                      data-bs-toggle="collapse"
                      className="nav-link px-0 align-middle "
                    >
                      <i
                        className={
                          selectedIndex === index
                            ? "text-dark fs-4 ms-2 " + icons[index]
                            : "text-warning fs-4 ms-2 " + icons[index]
                        }
                      ></i>{" "}
                      <span
                        className={
                          selectedIndex === index
                            ? "ms-1 d-none d-sm-inline  text-dark"
                            : "ms-1 d-none d-sm-inline text-white"
                        }
                      >
                        {item}
                      </span>
                    </a>
                  </li>
                ))}
              </ul>
              <hr className="bg-white" />
              <div className="dropdown pb-4">
                <a
                  href="#"
                  className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
                  id="dropdownUser1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={"images/" + user.image}
                    alt=""
                    width="30"
                    height="30"
                    className="rounded-circle"
                  />
                  <span className="d-none d-sm-inline mx-1">
                    {user.username}
                  </span>
                </a>
                <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
                  <li>
                    <a className="dropdown-item" href="#" onClick={() => setselectedindex(4)}>
                      Profile
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={handleLogout}
                    >
                      Sign out
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <AdminProvider>
            {messageBox.visible && <MessageBox />}
            {generalInfo.isProcessing && <Processing />}
            {order.visible && <OrderCard />}
            <div
              className="content col-xs-12 col-sm-9 col-md-9  col-xl-10 p-2 m-0 overflow-auto"
              style={{height:"100vh"}}
            >
              <div className="py-2 p-0 w-100 h- overflow-auto">
                <div className="row">
                  <div>
                    <nav
                      aria-label="breadcrumb"
                      className="bg-dark shadow rounded-3 p-3 mb-2"
                    >
                      <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item text-white">
                          <a href="#" className="text-decoration-none text-white">DashBoard  /</a>
                        </li>
                        <li className="breadcrumb-item"  >
                          <a href="#" className="text-decoration-none text-white">Admin   /</a>
                        </li>
                        <li
                          className="breadcrumb-item  text-warning"
                          aria-current="page"
                        >
                          {menuitem[selectedIndex]}
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>

                 {menuitem[selectedIndex] === "Home" && <AdminHome />}
                {menuitem[selectedIndex] === "Products" && <AdminProduct />}
                {menuitem[selectedIndex] === "Orders" && <AdminOrder />}
                {menuitem[selectedIndex] === "Sales" && <AdminSales />}
                {menuitem[selectedIndex] === "Users" && <AdminUser />}
              </div>
            </div>
          </AdminProvider>
        </div>
      </div>
    </>
  );
}

export default AdminDashboard;
