import { useGeneralInfoContext } from "../providers/genaralInfoProvider";
import { useMessageBoxContext } from "../providers/messageBoxProvider";

function MessageBox() {

  const {messageBox,setMessageBox} = useMessageBoxContext()
  const {generalInfo, setGeneralInfo} = useGeneralInfoContext()

  const handleBtnStatus = (status) => {
    setMessageBox({...messageBox,status:status,visible:false,message:"",isDelete:false,color:"success",deleteItem:""})
  }

  const handleBtnDelete = () => {

    const item = messageBox.deleteItem
    const id = messageBox.deleteIndex
    setGeneralInfo({...generalInfo, isProcessing:true})
    fetch("/delete",{
      method:"POST",
      body:JSON.stringify({id:id,deleteItem:item})
    })
    .then(res => res.json())
    .then(data => {
      if(data.error === 0){
        setGeneralInfo({...generalInfo,refresh:Math.floor(Math.random()*1000)})
        setMessageBox({ ...messageBox, visible: true,deleteIndex:-1,deleteItem:"",message:item + " has been deleted Successfully",color:"success" });
      }else{
        setMessageBox({ ...messageBox, visible: true,deleteIndex:-1,deleteItem:"",message: " something when wrong",color:"danger" });
      }
      setGeneralInfo({...generalInfo, isProcessing:false})
      setTimeout(() => {
        setMessageBox({ ...messageBox,deleteIndex:-1, visible:false,message:"",isDelete:false,color:"success",deleteItem:""})
      },3000)
    })
  }
  return (
    <>
      <section className="section-products d-flex align-items-center justify-content-center shadow m-0 p-0" style={{zIndex:"9",backgroundColor:"rgba(16,23,19,0.7)"}}>
        <div
          className={"row bg-white rounded shadow product-card p-2 border border-" + messageBox.color}
          style={{ width: "400px", height: "200px" }}
        >
          <div className="  d-flex justify-content-end">
            <button
              type="button"
              className="text-danger bg-light p-0 ps-2 pe-2"
              aria-label="Close"
              onClick={() => {
                setMessageBox({ ...messageBox,deleteIndex:-1, visible: false,message:"",isDelete:false,color:"success",deleteItem:"" })
              }}
            >X</button>
            </div>
          <div className="" style={{ height: "120px" }}>
            <p className={" text-center mt-4 text-" + messageBox.color}>{messageBox.message}</p>
          </div>
          <div className="d-flex align-items-center justify-content-center ">
          <button className={!messageBox.isDelete ? "btn bg-warning  btn-outline-light m-1 shadow text-dark" : "d-none"}
            onClick={() => handleBtnStatus(true)}
            >
              <i className="bi bi-check-lg me-1"></i>Ok
            </button>

            <button className={messageBox.isDelete  ? "btn bg-danger  btn-outline-light m-1 shadow text-white" : "d-none"}
            onClick={() => handleBtnDelete()}
            >
              <i className="bi bi-trash me-1 "></i>Delete
            </button>

          <button className="btn bg-dark  btn-outline-light m-1 shadow text-white"
            onClick={() => handleBtnStatus(false)}
            >
              <i className="bi bi-x me-1"></i>Cancel
            </button>
          
          </div>
        </div>
      </section>
    </>
  );
}
 export default MessageBox;