import logo from "../assets/logo2.png";
import { useNavigate } from "react-router-dom";
import { useGeneralInfoContext } from "../providers/genaralInfoProvider";
import Processing from "./processing";
import { useRef, useState } from "react";

function HeaderFooter({children}) {
  
  const navigate = useNavigate()
  const {generalInfo,setGeneralInfo} = useGeneralInfoContext()
  const [selectedIndex,setSelectedIndex] = useState(0)
  const menu = ["Home","Movies","Video Games"]

  const handleBtnLogin = () => {
    navigate("/Login")
  }

  const handleBtnSignup = () => {
    navigate("/Register")
  }

 
  
  return (
    <>
    {generalInfo.isProcessing && <Processing/>}
      {/*Nav*/}
      <nav className="navbar navbar-expand-lg bg-dark bg-body-tertiary" data-bs-theme="dark">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="Logo" height="56" className="d-inline-block align-text-top" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse col-sm-6" id="navbarNavAltMarkup">

            <div className={generalInfo.isHomePage ? "navbar-nav" : "d-none"}>
              { menu.map((item,index) => (
                  <a className={selectedIndex === index ? "nav-link active" : "nav-link" } href="#" key={index} onClick={() => {setSelectedIndex(index);setGeneralInfo({...generalInfo,homeSelectedIndex:index})}}> {item}</a>
              ))}

            </div>
          </div>
          <div className={generalInfo.isHomePage? "d-flex text-center ms-auto" : "d-none"}>
            <div className="d-flex flex-md-row flex-sm-column p-2 align-items-center">

          </div>
          <div className={generalInfo.isHomePage? "d-flex text-center ms-auto" : "d-none"}>
            <div className="d-flex flex-md-row flex-sm-column p-2 align-items-center">

              <div className="p-1">
                <button type="button" className="btn btn-warning text-dark float-end" style={{width:"80px"}} onClick={handleBtnLogin}>Login</button>
              </div>
              <div className="p-1">
                <button className="btn btn-outline-light float-end" style={{width:"80px"}}  onClick={handleBtnSignup}>Signup</button>


              </div>

            </div>
          </div>
        </div>
          </div>
       
      </nav>
      <div className="h-100 align-items-center justify-content-center position-relative p-1" style={{minHeight:"300px"}}>

        {children}
      </div>

      {/* footer */}
      <div className="d-flex bg-dark" >
        <div className="w-100">
          <footer className="text-center text-lg-start text-white p-2" style={{backgroundColor: "rgba(0, 0, 0, 0.3)"}}>
            <section className="">
              <div className="container text-center text-md-start mt-5 p-0">
                <div className="row mt-3 p-0">
                  <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4 m-0 ">
                    <h6 className="text-uppercase fw-bold mb-4">
                      <i className="bi bi-gem me-3"></i>Quadro Gamers
                    </h6>
                    <img src={logo} alt="Profile" className="logo " style={{height:"180px",width:"90%"}}/>
                  </div>

                  <div className="col-md-4 col-lg-4 col-xl-3  mx-auto mb-4">
                    <h6 className="text-uppercase fw-bold mb-4">
                      Contact
                    </h6>
                    <p className="text-warning">
                      <i  className="bi bi-telephone text-white me-2"></i>{process.env.REACT_APP_PHONE}
                    </p>
                    <p className="text-warning">
                      <i  className="bi bi-envelope text-white me-2"></i>
                      <a className="text-warning" href={"mailto:"+process.env.REACT_APP_SUPPORT_EMAIL}>{process.env.REACT_APP_SUPPORT_EMAIL}</a>
                    </p>
                    <p className="text-warning">
                      <i  className="bi bi-facebook text-white me-2"></i>Quadro_Gamers
                    </p>
                  </div>

                  <div className="col-md-4 col-lg-4 col-xl-3 mx-auto mb-md-0 mb-4">
                    <h6 className="text-uppercase fw-bold mb-4">Location</h6>
                    <p className="text-warning"><i className="bi bi-geo-alt me-3 text-white"></i> {process.env.REACT_APP_LOCATION}</p>
                    <p className="text-warning">
                      <i className="bi bi-envelope text-white text-white me-3"></i>
                      <a className="text-warning" href={"mailto:"+process.env.REACT_APP_INFO_EMAIL}>{process.env.REACT_APP_INFO_EMAIL}</a>
                    </p>
                    
                  </div>
                </div>
              </div>
            </section>
            <div className="text-center p-4">
              © 2021 Copyright:
              <span className="text-reset fw-bold">  Quadro Gamers</span>
            </div>
          </footer>
        </div>
      </div>
      
    </>
  );
}

export default HeaderFooter;
