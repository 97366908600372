import { useEffect } from "react";
import { useGeneralInfoContext } from "../providers/genaralInfoProvider";
import { useMessageBoxContext } from "../providers/messageBoxProvider";
import { useAdminContext } from "../providers/adminProvider";
import DataTable from "react-data-table-component";

 
 function EditAdmin() {

  const {generalInfo} = useGeneralInfoContext()
  const {messageBox,setMessageBox} = useMessageBoxContext()
  const {admins,setAdmins} = useAdminContext()

  const handleDelete = (row) =>{
    setMessageBox({...messageBox, visible:true, deleteIndex: row.id,deleteItem:"product" ,message:"Are you sure you want to delete " + row.name + " from the database ?"})
  }

  const handleEdit = (row) => {
    
  }

  const columns =  [
    {
      name:"Action",
      button:true,
      cell: (row) =>(
        <>
        <button className="btn btn-primary me-1 fs-5 bi-pen " onClick={() => handleEdit(row)}></button>
        <button className="btn btn-danger me-1 fs-5 bi-trash" onClick={() => handleDelete(row)}></button>
        </>
      )
  
    },
    {
      name: "image",
      selector: (row) => row.image,
      sortable:true
    },
    {
      name : "Surname",
      selector : row => row.surname,
        sortable :true,
    },
    {
      name: "Name",
      selector: (row) => row.othername,
      sortable: true,
    },
    
    {
      name : "Gender",
      selector : row => row.gender,
        sortable :true,
    }, 
     
      {
        name : "Email",
        selector : row => row.email
      },
       
  ]

    useEffect(() => {
        fetch("/getAdmins", {
          method: "GET",
        })
          .then((res) => res.json())
          .then((data) => {
            setAdmins(data)
          });
      }, [generalInfo.refresh]);

    return (
        <>
        <div className="h-auto w-100 m-0 p-0">
        {/*cardDetail.visible && <AdminProductCard type={fromType}/>*/}
        <div className="w-100">
                <DataTable
                title="Admins"
                columns={columns}
                data={admins}
                dense
                pagination
                striped
                theme="solarized"
                />
                
            </div>
            </div>
        </>
    )
}

export default EditAdmin;