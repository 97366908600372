import { useNavigate } from "react-router-dom";
import HeaderFooter from "./headerfooter"
import { useGeneralInfoContext } from "../providers/genaralInfoProvider";

function NotFound() {

  const {GeneralInfo,setGeneralInfo} = useGeneralInfoContext()
  const navigate = useNavigate()

  return (
    <>
      <HeaderFooter>
        <div
          className="d-flex flex-column align-item-center justify-content-center pt-2 w-100 "
        
        >
         <h5 className="text-center  fs-1" style={{color:"#ffa500"}}>404</h5>
          <div className="text-center "><img src="404_error.gif" style={{width:"400px",height:"350px"}} /></div>
          <h5 className="text-center fs-4">Page Not Found</h5>
          <h5 className="text-center fs-4 mt-2"><button className=" btn bg-warning text-dark" onClick={() => {navigate("/");setGeneralInfo({...GeneralInfo,homeSelectedIndex:0})}}><i className="bi bi-arrow-left me-2"></i>Go Back Home</button></h5>
        </div>
      </HeaderFooter>
    </>
  );
}

export default NotFound;