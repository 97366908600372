import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import HeaderFooter from "./headerfooter";
import { useGeneralInfoContext } from "../providers/genaralInfoProvider";
import { useMessageBoxContext } from "../providers/messageBoxProvider";
import { useLoginContext } from "../providers/loginProvider";

function AddAdmin() {

  const {generalInfo,setGeneralInfo} = useGeneralInfoContext()
  const {messageBox, setMessageBox} = useMessageBoxContext()
  const {user} = useLoginContext()

  useEffect(() => {
    setGeneralInfo({...generalInfo,isHomePage:false})
  },[])

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({ mode: "all" });
  const [alert, setAlert] = useState({
    visible: false,
    message: "",
    color: "text-danger",
  });

  const onSubmit = (formdata) => {
    setGeneralInfo({...generalInfo, isProcessing:true})
    fetch("/addAdmin", {
      method: "POST",
      body: JSON.stringify(formdata),
    })
      .then((res) => res.json())
      .then((data) => {
        setGeneralInfo({...generalInfo, isProcessing:false})
        if (data.error === 0) {
          setMessageBox({
            ...messageBox,
            visible: true,
            message: "New admin was added successfully",
          });
        } else if (data.error === 1) {
          setMessageBox({
            ...messageBox,
            visible: true,
            message:  "user with email " + formdata.email + " already  exist",
          });
         
        } else if (data.error === 2) {
          setMessageBox({
            ...messageBox,
            visible: true,
            message:  "something went wrong"
          });
        }
      });
  };
  
  return (
    <>
    <div className="row align-items-center justify-content-center overflow-y-auto m-0 shadow border rounded" >
    <h4 className="text-center">Add new system Admin</h4>
      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 bg-white shadow p-4 rounded m-0 mb-1" style={{height:"520px"}}>
        <div className="mt-4" >
          <div className="text-center ">
            <img
              src={"images/" + user.image}
              alt="avatar"
              className="rounded-circle img-fluid"
              style={{ width: "150px" }}
            />
            <h5 className="m-3">
              {user.username + " , " + user.othernames}
            </h5>
            <p className="text-muted m-3">{user.email}</p>
            <h5 className=" m-3" style={{color:"#ffa500"}}>{user.phone}</h5>
            <p className="m-3"><span className="" style={{color:"#ffa500"}}>{user.address} , </span> {user.city}</p>
          </div>
        </div>
      </div>
        <div
          className="col-sm-12 col-md-8 col-lg-7 col-xl-8 p-3 bg-white shadow  rounded m-0 overflow-scroll"
          style={{ height: "520px" }}
        >
          <form
            className=" p-1 p-md-1"
            onSubmit={handleSubmit(onSubmit)}
          >
           

            <div className="row">
              <div className="col-md-6 mb-3">
                <div className="form-outline">
                  <label className="form-label text-dark" htmlFor="surname">
                    SurName
                  </label>
                  <input
                    type="text"
                    name="surname"
                    className="form-control form-control-md"
                    {...register("surname", {
                      required: "Surname is required",
                      minLength: {
                        value: 3,
                        message: "Name can NOT be less  than three characters",
                      },
                      maxLength: {
                        value: 15,
                        message: "Name can NOT excede 15 characters",
                      },
                      pattern: {
                        value: /[a-zA-Z]+/,
                        message: "Name can only be letter",
                      },
                    })}
                  />
                  <p className="text-danger m-0" style={{ fontSize: "13px" }}>
                    {errors.surname?.message}
                  </p>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="form-outline">
                  <label
                    className="form-label text-dark"
                    htmlFor="othersNames"
                  >
                    Other Names
                  </label>
                  <input
                    type="text"
                    name="otherNames"
                    className="form-control form-control-md"
                    {...register("othernames", {
                      required: "Name is required",
                      minLength: {
                        value: 3,
                        message: "Name can NOT be less  than three characters",
                      },
                      maxLength: {
                        value: 15,
                        message: "Name can NOT excede 15 characters",
                      },
                      pattern: {
                        value: /[a-zA-Z]+/,
                        message: "Name can only be letter",
                      },
                    })}
                  />
                  <p className="text-danger m-0" style={{ fontSize: "13px" }}>
                    {errors.othernames?.message}
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mb-3">
                <h6 className="mb-2 pb-1  text-dark">Gender: </h6>

                <div className="form-check form-check-inline mt-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gender"
                    id="femaleGender"
                    value="Female"
                    {...register("gender", {
                      required: "Gender is required",
                    })}
                  />
                  <label
                    className="form-check-label text-dark"
                    htmlFor="femaleGender"
                  >
                    Female
                  </label>
                </div>

                <div className="form-check form-check-inline mt-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gender"
                    id="maleGender"
                    value="Male"
                    {...register("gender", {
                      required: "Gender is required",
                    })}
                  />
                  <label
                    className="form-check-label text-dark"
                    htmlFor="maleGender"
                  >
                    Male
                  </label>
                </div>
                <p className="text-danger m-0" style={{ fontSize: "13px" }}>
                    {errors.gender?.message}
                  </p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <div className="form-outline">
                  <label className="form-label text-dark" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="form-control form-control-md"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "invalid email address ",
                      },
                    })}
                  />
                  <p className="text-danger m-0" style={{ fontSize: "13px" }}>
                    {errors.email?.message}
                  </p>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="form-outline">
                  <label className="form-label text-dark" htmlFor="phone">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    className="form-control form-control-md"
                    {...register("phone", {
                      required: "Phone number id required",
                      minLength: {
                        value: 10,
                        message: "Phone number should be 10 characters",
                      },
                      maxLength: {
                        value: 10,
                        message: "Phone number should be 10 characters",
                      },
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Phone number can only numbers",
                      },
                    })}
                  />
                  <p className="text-danger m-0" style={{ fontSize: "13px" }}>
                    {errors.phone?.message}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <div className="form-outline">
                  <label className="form-label text-dark" htmlFor="password">
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    className="form-control form-control-md"
                    {...register("password", {
                      required: "password is required",
                      minLength: {
                        value: 8,
                        message: "password MUST be atleast 8 characters",
                      },
                      maxLength: {
                        value: 16,
                        message: "password should NOT excede 16 characters",
                      },
                      pattern: {
                        value:
                          /^(?=.*[0-9])(?=.*[!@#$%^&*.,])[a-zA-Z0-9!@#$%^&*.,]{8,16}$/,
                        message:
                          "password should contain atleast 1 uppercase, 1 lowercase, 1 number and 1 special character",
                      },
                    })}
                  />
                  <p className="text-danger m-0" style={{ fontSize: "13px" }}>
                    {errors.password?.message}
                  </p>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="form-outline">
                  <label
                    className="form-label text-dark"
                    htmlFor="confirmPassword"
                  >
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    name="confirmPassword"
                    className="form-control form-control-md"
                    {...register("confirmpassword", {
                      validate: (match) => {
                        const password = getValues("password");
                        return match === password || "Password DON'T match";
                      },
                    })}
                  />
                  <p className="text-danger" style={{ fontSize: "13px" }}>
                    {errors.confirmpassword?.message}
                  </p>
                </div>
              </div>
            </div>
            <button className="btn btn-outline-warning w-100 mt-2 bg-dark text-white " type="submit">
              AddAdmin
            </button>
          
          </form>
        </div>
        </div>
    </>
  );
}

export default AddAdmin;
