import Dashboard from "./components/dashboard"
import { Routes, Route,  Navigate} from "react-router-dom";
import "./App.css";
import AdminDashboard from "./components/admin";
import ProductsProvider from "./providers/productsProvider";
import CategoryProvider from "./providers/categoryProvider";
import CardDetailProvider from "./providers/cardDetailProvider";
import CartProvider from "./providers/cartProvider";
import Login from "./components/login";
import { useLoginContext } from "./providers/loginProvider";
import Signup from "./components/signup";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/passwordreset";
import GeneralInfoProvider, { useGeneralInfoContext } from "./providers/genaralInfoProvider";
import Home from "./components/home";
import { useEffect } from "react";
import NotFound from "./components/notFound";
import EmailVerification from "./components/emailVerification";

function App() {
  const { user } = useLoginContext();
  const {generalInfo,setGeneralInfo} = useGeneralInfoContext()

  const host = window.location.hostname
  useEffect(()=>{
setGeneralInfo({...generalInfo,host:host})
  },[])

  return (
    <>
   
        <ProductsProvider>
          <CategoryProvider>
            <CartProvider>
              <CardDetailProvider>
                <Routes>
                  <Route path="/Login" element={user !== null ? user.isAdmin ? <Navigate to={"/AdminDashboard"} /> : <Navigate to={"/Dashboard"} /> : <Login /> } />
                  <Route path="/Dashboard" element={user !== null ? <Dashboard /> :  <Navigate to={"/Login"} />}  />
                  <Route path="/Register" element={<Signup /> } />
                  <Route path="/AdminDashboard" element={user ? <AdminDashboard /> :  <Navigate to={"/Login"} /> } />
                  <Route path="/ForgotPassword" element={<ForgotPassword />} />
                  <Route path="/ResetPassword/:token" element={<ResetPassword/>} />
                  <Route path="/EmailVerification/:email" element={!user ?<EmailVerification/>:  <Navigate to={"/Login"} /> } />
                  <Route path="/NotFound" element={<NotFound />} />
                  <Route path="/" element={<Home />} exact/>
                  <Route path="*" element={<Navigate  to="/NotFound" />} />
                </Routes>
              </CardDetailProvider>
            </CartProvider>
          </CategoryProvider>
        </ProductsProvider>
    </>
  );
}

export default App;
