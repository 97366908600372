import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLoginContext } from "../providers/loginProvider";
import { useNavigate } from "react-router-dom";
import HeaderFooter from "./headerfooter";
import { useGeneralInfoContext } from "../providers/genaralInfoProvider";
import Proccess from "./process";

function Login() {
  const navigate = useNavigate();
  const { user, setUser } = useLoginContext();
  const [alert, setAlert] = useState({
    visible: false,
    message: "",
    color: "danger",
  });

  const {generalInfo,setGeneralInfo} = useGeneralInfoContext()

  useEffect(() => {
    setGeneralInfo({...generalInfo, isHomePage:false})
    setUser( JSON.parse(localStorage.getItem("user")) )
     
  },[])

  useEffect( () => {
    setGeneralInfo({...generalInfo,page:"Content"})
    if(user){
      if(user.isAdmin){
        navigate("/AdminDashboard");
      }else{
        navigate("/Dashboard");
      }
    }
  },[user])

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({ mode: "all" });

  const handleForgotPassClick = () => {
    navigate("/ForgotPassword")
  }

  const onSubmit = (data) => {
    setGeneralInfo({...generalInfo, isProcessing:true})
    fetch("/getUser", {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setGeneralInfo({...generalInfo, isProcessing:false})
        if (data[0].error === 0) {
          setUser({
            ...user,
            isAdmin:data[1].isAdmin,
            isLoggedIn: true,
            userid:data[1].id,
            username: data[1].surname,
            othernames:data[1].othernames,
            image: data[1].image,
            email:data[1].email,
            gender:data[1].gender,
            phone:data[1].phone,
            preference:data[1].preference.map(p => (
              {value:p , label:p }
            )),
            points:data[1].points,
            redeem:data[1].redeem,
          });
          localStorage.setItem("user",
          JSON.stringify({
            isAdmin:data[1].isAdmin,
            isLoggedIn: true,
            userid:data[1].id,
            username: data[1].surname,
            othernames:data[1].othernames,
            image: data[1].image,
            email:data[1].email,
            gender:data[1].gender,
            phone:data[1].phone,
            preference:data[1].preference.map(p => (
              {value:p , label:p }
            )),
            points:data[1].points,
            redeem:data[1].redeem,
          }) 

          )

          setGeneralInfo ({ ...generalInfo, page: "Content" });

        
          
        } else if (data[0].error === 1) {
          setAlert({
            ...alert,
            message: "Incorrect Email or Password",
            color: "danger",
            visible: true,
          });
        } else if (data[0].error === 2) {
          setAlert({
            ...alert,
            visible: true,
            message: "Your email is not verified Please check your email for Verification email " ,
            color: "danger",
          });
        } else if (data[0].error === 3) {
          setAlert({
            ...alert,
            message: "Something went wrong please try again",
            color: "danger",
            visible: true,
          });
        }
        setTimeout(() => {
          setAlert({ ...alert, visible: false });
        }, 2000);
      });
  };

  return (
    <>
      <HeaderFooter>
        
        <div
          className="login col-xs-10 col-sm-7 col-md-6 col-lg-5 col-xl-4 bg-dark m-0  mb-3"
          style={{ height: "auto",marginTop:"8vh",marginBottom:"8vh" }}
        >
          <div className="card-body d-flex p-4 pt-1 text-left">
            <form
              className="mb-md-4 mt-md-4 pb-4 w-100"
              onSubmit={handleSubmit(onSubmit)}
            >
              <h2 className="fw-bold mt-3 mb-2 text-uppercase text-white">
                Login
              </h2>
              {alert.visible && (
              <div className={"alert  alert-info  fade show border border-" + alert.color + " text-" + alert.color} role="alert">
                <button
                  type="button"
                  className="btn text-danger  float-end"
                  style={{ position: "relative", top: "-10px" }}
                  onClick={(e) => {
                    setAlert({ ...alert, visible: false });
                  }}
                >
                  X
                </button>
                <span className={alert.color}>{alert.message}</span>
              </div>
            )}

              <div className="form-outline form-white mb-2">
                <label className="form-label text-white" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  className="form-control form-control-md"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "invalid email address ",
                    },
                  })}
                />
                <span className="text-danger" style={{ fontSize: "13px" }}>
                  {errors.email?.message}
                </span>
              </div>

              <div className="form-outline form-white mb-2">
                <label
                  className="form-label text-white text-left"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  className="form-control form-control-md"
                  {...register("password", {
                    required: "password is required",
                  })}
                />
                <span className="text-danger" style={{ fontSize: "13px" }}>
                  {errors.password?.message}
                </span>
              </div>

              <p className="small mb-2 pb-lg-2">
                <a
                  className="text-white-50"
                  href="#"
                  onClick={() => handleForgotPassClick()}
                >
                  Forgot password?
                </a>
              </p>

             { generalInfo.isProcessing ?
             <Proccess/>

             :<button className="btn btn-warning text-dark w-100 m-0" type="submit">
                Login
              </button>}
              <p className="mt-2 text-white">
                Don't have an account?{" "}
                <a href="#"
                  className="text-info fw-bold"
                  onClick={() => navigate("/Register")}
                >
                  Sign Up
                </a>
              </p>
            </form>
          </div>
        </div>
      </HeaderFooter>
    </>
  );
}

export default Login;
