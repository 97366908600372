import { useState,useContext,createContext} from "react";


const ProductsContext = createContext();
function ProductsProvider({children}) {

    
    const [products,setProducts] = useState([])
    const [filterProducts,setFilterProducts] = useState([])
    const [searchProducts,setSearchProducts] = useState([])

    return (
           <ProductsContext.Provider value={{ products,setProducts,searchProducts,setSearchProducts, filterProducts, setFilterProducts}}>
                {children}
            </ProductsContext.Provider>
    )
}

export const useProductsContext = () => useContext(ProductsContext);
export default ProductsProvider;