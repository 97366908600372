import { useState,useContext,createContext} from "react";


const GeneralInfoContext = createContext();
function GeneralInfoProvider({children}) {

    const [generalInfo, setGeneralInfo] = useState({
        isHomePage:true,
        isProcessing:false,
        isUser:false,
        refresh:0,
        count:0,
        refreshOrder:0,
        activeIndex:0,
        Page:"Content",
        host:"",
        homeSelectedIndex:0,
        contentHeading:"Recommendation",
       
    })


    return (
           <GeneralInfoContext.Provider value={{ generalInfo,setGeneralInfo }}>
                {children}
            </GeneralInfoContext.Provider>
    )
}

export const useGeneralInfoContext = () => useContext(GeneralInfoContext);
export default GeneralInfoProvider;