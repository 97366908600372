import { useEffect, useRef, useState } from "react";
import logo from "../assets/logo.png";
import Nav from "./topnav";
import Sidenav from "./sidenav";
import Content from "./content";
import ProductCard from "./productCard";
import Cart from "./cart";
import { useProductsContext } from "../providers/productsProvider";
import  {
  useCategoryContext,
} from "../providers/categoryProvider";
import { useCardDetailContext } from "../providers/cardDetailProvider";
import { useCartContext } from "../providers/cartProvider";
import UserProfile from "./userProfile";
import { useGeneralInfoContext } from "../providers/genaralInfoProvider";
import MessageBox from "./messagebox";
import { useMessageBoxContext } from "../providers/messageBoxProvider";
import OrderCard from "./orderCard";
import { useOrderContext } from "../providers/orderProvider";
import Orders from "./orders";
import LoyaltyPoints from "./loyaltyPoints";

function Dashboard() {
  const { setCategories } = useCategoryContext();
  const { products, setProducts, setFilterProducts, searchProducts, setSearchProducts} = useProductsContext();
  const [isMovies, setIsMovies] = useState(true);
  const [selectedindex, setselectedindex] = useState(0);
  const [gameCount, setGameCount] = useState(0);
  const [movieCount, setMovieCount] = useState(0);
  const [category, setCategory] = useState("");
  const [type, setType] = useState("Movies");
  const [ setSubCategory] = useState("");
  const [selectedSideindex, setselectedSideindex] = useState(-1);
  const { cardDetail } = useCardDetailContext();
  const { cart } = useCartContext();
  const { generalInfo, setGeneralInfo } = useGeneralInfoContext();
  const { messageBox } = useMessageBoxContext();
  const { order } = useOrderContext();

  const fetchCount = (type) => {
    fetch("/getCount",{
      method:"POST",
      body:JSON.stringify({productType:type})
    })
    .then( res => res.json() )
    .then( data => {
      if(type === "Movies"){
        setMovieCount(data.count)
      }else if(type === "Games"){
        setGameCount(data.count)
      }
     })
    }

  useEffect(() => {
   fetchCount("Movies")
  },[])

 const fetchProducts = (offset,type) => {
  setGeneralInfo({ ...generalInfo, isProcessing: true });
  fetch("/getProducts", {
    method: "POST",
    body: JSON.stringify({ 
      offset: offset,
      productType:type
    }),
  })
    .then((res) => res.json())
    .then((data) => {
      setGeneralInfo({ ...generalInfo, isProcessing: false });
      setProducts((prev) => [...prev, ...data[1]]);
     
      if(offset === 0){
        setCategories(data[0])
      }
    });
};

const getProducts = (type,count) => {
  let i = 0;
  let offset = 0;
  while (i <count) {
    fetchProducts(offset,type);
    i += 18;
    offset = i;
  }
}

useEffect(() => {
  getProducts("Movies",movieCount)
},[movieCount]);

useEffect(() => {
  getProducts("Games",gameCount)
},[gameCount]);

useEffect(() => {
  if(type === "Movies"){
    setSearchProducts(products.filter(product => product.product_type === "Movies" ))
    setFilterProducts(products.filter(product => product.product_type === "Movies" ))
  }else  if(type === "Games"){
    setSearchProducts(products.filter(product => product.product_type === "Games" ))
    setFilterProducts(products.filter(product => product.product_type === "Games" ))
  }
  
},[products]);

useEffect( () => {
  if(type === "Movies"){
    let p = searchProducts.map( (product) => (product.sub_category ))
    setFilterProducts(searchProducts)
    setCategories( Array.from(new Set(p)) )
  }else if(type === "Games"){
    let p = searchProducts.map( (product) => (product.category ))
    setCategories( Array.from(new Set(p)) )
  }
 
},[searchProducts])

  const handleMenuClick = (index, item) => {
    setselectedSideindex(-1);
    setselectedindex(index);
    if (index === 0) {
      setIsMovies(true);
    } else {
      setIsMovies(false);
    } 
  setProducts(prev => [])
    if (item === "loyalty Points") {
      setGeneralInfo({
        ...generalInfo,
        page: "Loyalty Points",
        contentHeading: "Recommendation",
      });
    } else if (item === "Games") {
      setGameCount(0)
      setGeneralInfo({
        ...generalInfo,
        page: "Content",
        contentHeading:item
      });
      setType("Games")
      fetchCount("Games")
    } else if (item === "Movies") {
      setMovieCount(0)
      setGeneralInfo({
        ...generalInfo,
        page: "Content",
        contentHeading:"Recommendation"
      });
      setType("Movies")
      fetchCount("Movies")
    }
  };

  const handleDropDownClick = (e) => {
    setGeneralInfo({ ...generalInfo, page: e.target.firstChild.data });
    setselectedindex(-1);
  };

  const handleOnChange = (value) => {
    setCategory(value);
    setGeneralInfo({...generalInfo,contentHeading:value})
    setSearchProducts(products.filter((product) => product.category === value))
  };

  const handleSideMenuClick = (item, index) => {
    setselectedSideindex(index);
    setGeneralInfo({...generalInfo,contentHeading:item})
    if (type === "Movies") {
      setFilterProducts(searchProducts.filter((movie) => movie.sub_category === item))
    } else {
      setFilterProducts(searchProducts.filter((game) => game.category === item))
    }
  };

  return (
    <>
      <div
        className="row header fixed-top  bg-dark bg-body-tertiary  m-0 p-0"
        data-bs-theme="dark"
      >
        {messageBox.visible && <MessageBox />}
        {cardDetail.visible && <ProductCard />}
        {order.visible && <OrderCard />}
        {cart.visible && <Cart />}
        {generalInfo.isUser && <UserProfile />}
        <div className="col-xl-2 col-lg-2 col-sm-12 col-xs-12  text-center">
          <img src={logo} alt="Profile" className="logo " />
        </div>
        <div className="col-xl-10 col-lg-10 col-sm-12 p-0 col-xs-8 m-0 ">
          <Nav
            onMenuClick={handleMenuClick}
            indexed={selectedindex}
            onDropDownClick={handleDropDownClick}
          />
        </div>
      </div>

      <div className="body  p-0 m-0 ">
        <div className="bg-dark ">
          <Sidenav
            onSideMenuClick={handleSideMenuClick}
            selectedindex={selectedSideindex}
          />
        </div>
        <div className="maincontent overflow-scroll" >
          {generalInfo.page === "Content" && 
            <Content
              isMovie={isMovies}
              onChange={handleOnChange}
              category={category}
            />
          }
          {generalInfo.page === "My Orders" && <Orders />}
          {generalInfo.page === "Profile" && <UserProfile />}
          {generalInfo.page === "Loyalty Points" && <LoyaltyPoints />}
        </div>
      </div>
    </>
  );
}

export default Dashboard;
