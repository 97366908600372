import { useEffect, useRef, useState } from "react";
import { useProductsContext } from "../providers/productsProvider";
import { useLoginContext } from "../providers/loginProvider";
import { useNavigate } from "react-router-dom";
import { useGeneralInfoContext } from "../providers/genaralInfoProvider";
import { useCartContext } from "../providers/cartProvider";

function TopNav({ onMenuClick, indexed, onDropDownClick }) {
  const { products, setSearchProducts } = useProductsContext();
  const { generalInfo, setGeneralInfo } = useGeneralInfoContext();
  const { cartItems, cart, setCart } = useCartContext();
  const [searchValue, setSearchValue] = useState();
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const search = useRef();

  const menuitem = ["Movies", "Games", "loyalty Points"];
  const { user, setUser } = useLoginContext();

  const handleSeachClick = (e) => {
    let val = search.current.value;
    if (val === "") {
      setSearchProducts(() => products);
    } else {
      const newData = products.filter((product) => {
        return product.name.toLowerCase().includes(val.toLowerCase());
      });
      setSearchProducts(() => newData);
    }
  };

  const handleLogout = (e) => {
    fetch("/logout", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        localStorage.setItem("user", null);
        setUser(null);
        setGeneralInfo({ ...generalInfo, page: "Content" });
        navigate("/Login");
      });
  };
  return (
    <>
      <div
        className="row m-1 p-1 "
        style={{ width: "95%", paddingLeft: "5px" }}
      >
        <form className="d-flex col-md-12 m-0 p-0" role="search">
          <input
            className="form-control me-2"
            type="search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search"
            aria-label="Search"
            ref={search}
          />
          <button
            className="btn bg-warning text-dark col-sm-2"
            type="button"
            onClick={handleSeachClick}
          >
            Search
          </button>
        </form>
      </div>
      <div className=" p-0 m-0 " style={{ width: "95%" }}>

        {/* Navbar  */}
        <nav className="navbar navbar-expand-lg  p-0">
          {/* Container wrapper  */}
          <div className="container-fluid">
            {/* Toggle button  */}
            <button
              className="navbar-toggler m-1 p-0 bg-dark-subtle ms-aut "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              style={{
                width: "40px",
                height:"35px",
                position: "relative",
                right: "10px",
                right: "0",
                margin: "auto",
              }}
            >
              <i className="fs-2 bi-list"></i>
            </button>

            {/* Collapsible wrapper  */}
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
             
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {menuitem.map((item, index) => (
                  <li
                    className={
                      indexed === index
                        ? "nav-item text-white ms-3 me-3 m-0 p-0 bg-warning"
                        : "nav-item ms-3 me-3 menu-item  text-white m-0 p-0 "
                    }
                    key={index}
                    onClick={(e) => onMenuClick(index, item)}
                  >
                    <a
                      className={
                        indexed === index
                          ? "nav-link m-0 p-2 ms-2 me-2 bg-warning text-dark"
                          : "nav-link m-0 p-2 ms-2 me-2 text-white"
                      }
                      aria-current="page"
                      href="#"
                      id={item}
                    >
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
              {/* Left links  */}
            </div>
            {/* Collapsible wrapper  */}

            {/* Right elements  */}
            <div className="d-flex align-items-center">
              {/* Icon  */}
              <a className="text-reset me-3" href="#">
                <i className="fas fa-shopping-cart"></i>
              </a>

              {/* Cart  */}
              <div
                className="mt-auto p-1 ms-auto"
                onClick={() => setCart({ ...cart, visible: true })}
                style={{ width: "60px", height: "40px" }}
              >
                <i className="fs-4 bi-bag-fill pt-1 pb-1 ps-2 pe-2  text-warning"></i>
                <span
                  className="bg-white text-dark fw-bold rounded-pill p-0 ps-2 pe-2"
                  style={{ position: "relative", left: "28px", top: "-40px" }}
                >
                  {cartItems.length}
                </span>
              </div>
             
              {/* Avatar  */}
              <span className="dropdown   mt-1 ms-4 mt-0 pt-0">
                <a
                  href="#"
                  className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={"images/" + user.image}
                    alt=""
                    width="40"
                    height="40"
                    className="rounded-circle me-2"
                  />
                  <strong>{user.username}</strong>
                </a>
                <ul className="dropdown-menu dropdown-menu-dark me-auto text-small shadow">
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={onDropDownClick}
                    >
                      Profile
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={onDropDownClick}
                    >
                      My Orders
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={handleLogout}>
                      Sign out
                    </a>
                  </li>
                </ul>
              </span>
            </div>
            {/* Right elements  */}
          </div>
          {/* Container wrapper */}
        </nav>
        {/* Navbar */}
      </div>
    </>
  );
}

export default TopNav;
