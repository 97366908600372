import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useCardDetailContext } from "../providers/cardDetailProvider";
import { useMessageBoxContext } from "../providers/messageBoxProvider";
import { useProductsContext } from "../providers/productsProvider";
import AdminProductCard from "./AdminProductCard";
import { useGeneralInfoContext } from "../providers/genaralInfoProvider";

function AdminProduct() {
  const { messageBox, setMessageBox } = useMessageBoxContext();
  const { cardDetail, setCardDetail } = useCardDetailContext();
  const [fromType, setFormType] = useState("");
  const { products, setProducts } = useProductsContext();
  const { generalInfo } = useGeneralInfoContext();

  const handleDelete = (row) => {
    setMessageBox({
      ...messageBox,
      visible: true,
      deleteIndex: row.id,
      deleteItem: "product",
      isDelete:true,
      message:
        "Are you sure you want to delete " + row.name + " from the database ?",
    });
  };

  const handleEdit = (row) => {
    setFormType("formUpdate");
    setCardDetail({
      ...cardDetail,
      visible: true,
      id: row.id,
      name: row.name,
      price: row.price,
      date: row.releasedate,
      image: row.image,
      description: row.description,
      category: row.category,
      quantity: row.quantity,
      subCategory: row.sub_category,
      productType: row.product_type,
    });
  };

  const columns = [
    {
      name: "Action",
      button: true,
      cell: (row) => (
        <>
          <button
            className="btn btn-warning me-1 fs-5 bi-pen text-dark "
            onClick={() => handleEdit(row)}
          ></button>
          <button
            className="btn btn-danger me-1 fs-5 bi-trash"
            onClick={() => handleDelete(row)}
          ></button>
        </>
      ),
    },
    {
      name: "Products",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row.price,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.product_type,
      sortable: true,
    },

    {
      name: "Category",
      selector: (row) => row.category,
    },
    {
      name: "Sub Category",
      selector: (row) => row.sub_category,
      sortable: true,
    },
    {
      name: "Release Date",
      selector: (row) => row.releasedate,
    },
  ];

  useEffect(() => {
    fetch("/getAllProducts", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setProducts(data);
      });
  }, [generalInfo.refresh]);

  return (
    <>
      <div className="  w-100 m-0 p-0  overflow-scroll" style={{}}>
        {cardDetail.visible && <AdminProductCard type={fromType} />}
        <div className=" gap-2 d-sm-flex justify-content-sm-end m-0 p-0">
          <button
            className="btn btn-warning text-dark btn-outline-light m-1"
            style={{ width: "200px" }}
            onClick={() => {
              setCardDetail({ ...cardDetail, visible: true });
              setFormType("formAdd");
            }}
          >
            Add Product
          </button>
        </div>
        <div className="w-100  overflow-auto">
          <DataTable
            title="Products"
            columns={columns}
            data={products}
            dense
            pagination
            striped
            theme="solarized"
          />
        </div>
      </div>
    </>
  );
}

export default AdminProduct;
