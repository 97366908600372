function Process() {

 
    return (
      <>
       <div className="text-center m-0 p-0" >
                <i className="spinner-grow text-warning fs-5"></i>
                <i className="spinner-grow fs-5" style={{color:"#ffa500"}}></i>
                <i className="spinner-grow text-warning fs-5"></i>
                <i className="spinner-grow  fs-5" style={{color:"#ffa500"}}></i>
              </div>
      </>
    );
  }
   export default Process;