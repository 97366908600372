import { useState,useContext,createContext} from "react";


const OrderContext = createContext();
function OrdersProvider({children}) {

    const [order, setOrder] = useState({
        visible:false,
        title:"",
        detail:[]
       
    })
    const [orderItems, setOrderItems] = useState([])


    return (
           <OrderContext.Provider value={{ order,orderItems,setOrder,setOrderItems }}>
                {children}
            </OrderContext.Provider>
    )
}

export const useOrderContext = () => useContext(OrderContext);
export default OrdersProvider;