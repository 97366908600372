import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useProductsContext } from "../providers/productsProvider";
import { useGeneralInfoContext } from "../providers/genaralInfoProvider";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js/auto";
import { Bar } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

function AdminHome() {
  const { products, setProducts } = useProductsContext();
  const { generalInfo, setGeneralInfo } = useGeneralInfoContext();
  const [chartData, setChartData] = useState({
    labels: [
      "Monday",
      "Tuesday",
      "Wedesday",
      "Thusday",
      "friday",
      "Saterday",
      "Sunday",
    ],
    datasets: [
      {
        label: "This Week's Sales",
        data: [90, 70, 60, 180, 110, 120, 150],
        backgroundColor: ["#133337", "#ffa500", "#00a86b"],
      },
    ],
  });

  useEffect(() => {
    setGeneralInfo({ ...generalInfo, isHomePage: false });
    fetch("/getSales", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => {
        setProducts(res.products);
        /*setChartData({...chartData,
          labels:res.products.map( item => item.day),
          datasets:[{
            label: "This Week's Sales",
            data:res.products.map( item => item.sales),
            backgroundColor:["black","yellow","grey"]
          }]
          })*/
      });
  }, [generalInfo.refresh]);

  const columns = [
    {
      name: "image",
      selector: (row) => row.image,
    },
    {
      name: "Products",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row.price,
    },
    {
      name: "Product Type",
      selector: (row) => row.product_type,
    },
    {
      name: "Release Date",
      selector: (row) => row.releasedate,
      sortable: true,
    },
  ];

  let total = 0;
  products.some((item) => {
    total += Number.parseInt(item.sales);
  });
  let todayTotal = 0;
  products.some((item) => {
    todayTotal += Number.parseInt(item.sales);
  });

  return (
    <>
      <div className="container w-100  h-100 m-0 p-0  overflow-auto">
        <div className="row align-items-center justify-content-center h-auto m-0 p-0">
          <div
            className="row col-xs-10 col-sm-6 col-md-5 col-lg-5 bg-warning shadow mt-0 mb-1 rounded m-auto align-items-center justify-content-center"
            style={{ height: "200px" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title text-dark mb-2">Today's Sales</h5>
              <i className="fs-1 bi-cash-coin text-success mb-1 me-2"></i>
              <span className="text-dark fs-1 mb-2">{total} Ksh</span>
              <p className="card-text text-dark">
                From Movie and Game rent Today
              </p>
            </div>
          </div>

          <div
            className="row col-xs-10 col-sm-6 col-md-5 col-lg-5 bg-dark shadow  mt-1 mb-1 rounded m-auto align-items-center justify-content-center"
            style={{ height: "200px" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title text-white mb-2">This Month Sales</h5>
              <i className="fs-1 bi-cart4 text-warning mb-1 me-2"></i>
              <span className="text-white fs-1 mb-2">{total} Ksh</span>
              <p className="card-text text-white">
                From Movie and Game rent this month
              </p>
            </div>
          </div>
        </div>
        <div
          className="d-flex  shadow mt-2 m-2 p-0 "
          style={{ height: "70%", width: "98%" }}
        >
          {/* <DataTable
          title="Recent Sales"
            columns={columns}
            data={products}
            fixedHeader 
            pagination
            dense
            striped
          />*/}

          <Bar data={chartData} style={{ height: "200px" }} />
        </div>
      </div>
    </>
  );
}

export default AdminHome;
